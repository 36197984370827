import Component from "@/components/Flowbuilder/Component/Component";

export default class FirstOrNullComponent extends Component
{
    id: string | null;

    valueObject: any;

    name: string;

    constructor(id: string | null, valueObject: any, name: string) {
        super('');
        this.id = id;
        this.valueObject = valueObject;
        this.name = name;
    }

    getValueObject(): string
    {
        return this.valueObject;
    }

    setValueObject(valueObject: any): void
    {
        this.valueObject = valueObject;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }
}
