

import { Options, Vue } from "vue-class-component";
import LoopComponent from "@/components/Flowbuilder/Component/Loop/LoopComponent";

@Options({
    components: { },
    props: {
        index: String,
        id: Number,
        component: LoopComponent
    },
    data() {
        return {
            displayTrashCan: false
        }
    },
    methods: {
        displayTrash() {
            this.displayTrashCan = true;
        },
        hideTrash() {
            this.displayTrashCan = false;
        }
    }
})
export default class Loop extends Vue {}
