
import ComponentDraw from "@/components/Flowbuilder/Ui/PlumbDrawer/ComponentDraw";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import {Vue} from "vue-class-component";
import ComponentViewFactory from "@/components/Flowbuilder/Factory/ComponentViewFactory";
import {App, createApp} from "vue";
import FlowBuilder from "@/components/Flowbuilder/Builder/FlowBuilder";
import { createI18n } from 'vue-i18n'
import nl from "../../../../../locales/nl.json";
import en from "../../../../../locales/en.json";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";


export default class ContainerHelper {
    private instance: BrowserJsPlumbInstance;

    private workflowId: string;

    constructor(instance: BrowserJsPlumbInstance, workflowId: string) {
        this.instance = instance;
        this.workflowId = workflowId;
    }

    createContainer(component: ViewComponent, label: string, position: number, walkedPath: string): HTMLElement {
        const drawer: ComponentDraw = new ComponentDraw(this.instance);

        const container: HTMLElement = drawer.draw(
            component.getPositionX(),
            component.getPositionY(),
            component,
            position,
            walkedPath
        );

        const id: number = Math.floor(Math.random() * (99999 - 10000 + 1) + 99999);

        container.setAttribute(
            'component-position',
            String(position)
        );

        container.setAttribute(
            'component-type',
            component.getType()
        );

        container.setAttribute(
            'component-id',
            String(id)
        );

        container.setAttribute(
            'component-label',
            label
        );

        return container;
    }

    createView(component: ViewComponent): App {

        const factory: ComponentViewFactory = new ComponentViewFactory();

        const view: Vue = factory.create(
            component.getType()
        )

        const i18n = createI18n({
            locale: "en",
            globalInjection: true,
            fallbackLocale: "en",
            messages: {nl, en},
            legacy: false
        });

        return createApp({extends: view}, {
            id: 'ref',
            component: component.getComponent(),
            type: component.getType()
        }).use(i18n);
    }

    calculateOffsetY(type: string, startOffsetY: number): number {
        let nextOffsetY: number = startOffsetY;

        switch (type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetY -= 0;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetY += 80;
            break;
        default:
            nextOffsetY += FlowBuilder.DEFAULT_DISTANCE_Y;
            break;
        }

        return nextOffsetY;
    }

    calculateOffsetX(type: string, startOffsetX: number): number {
        let nextOffsetX: number = startOffsetX;

        switch (type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetX -= FlowBuilder.DEFAULT_DISTANCE_DECISION_FALSE_X;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetX += FlowBuilder.DEFAULT_DISTANCE_DECISION_TRUE_X;
            break;
        }

        return nextOffsetX;
    }

    removeComponent(view: ViewComponent, collection: ViewComponent[]): void {

        for (let e = 0; e < collection.length; e++) {

            const i = e;

            if (collection[i].getId() === view.getId()) {
                collection.splice(i, 1);
            }

            if (typeof collection[i] !== 'undefined' && collection[i].getChildNodes().length > 0) {
                collection[i].setChildNodes(
                    this.remove(view, collection[i].getChildNodes())
                );
            }
        }
    }

    remove(view: ViewComponent, collection: ViewComponent[]): ViewComponent[] {
        let add = true;
        const n: ViewComponent[] = [];

        for (let e = 0; e < collection.length; e++) {

            const i = e;

            if (collection[i].getId() === view.getId()) {
                add = false;
            }

            if (add) {
                n.push(collection[i]);
            }

            if (typeof collection[i] !== 'undefined' && collection[i].getChildNodes().length > 0) {
                collection[i].setChildNodes(
                    this.remove(view, collection[i].getChildNodes())
                );
            }
        }

        return n;
    }
}