

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";

    @Options({
        data() {
            return {
                user: null,
                products: Array,
                billingType: '',
                plan: '',
                productSelected: null
            }
        },
        methods: {
            regularPrice(price: string): string
            {
                const annualPrice = parseInt(price) * 12;
                return (annualPrice ).toFixed(0) + ',-'
            },
            discountPrice(price: string): string
            {
                const perc = 6;
                const annualPrice = parseInt(price) * 12;
                const discount = (annualPrice / 100) * perc;

                return (annualPrice - discount).toFixed(0) + ',-'
            },

            switchBillingType(type: string): void {
                this.billingType = type;
            },
            setProduct(product: any): void
            {
                this.productSelected = product;
            },
            checkout() {

                const basket = {
                    "product": this.productSelected.reference,
                    "billing": this.productSelected.reference !== 'free' && this.billingType === 'none'
                        ? 'month'
                        : this.billingType
                };

                ApiRequest('POST', '/checkout', basket).then (async (response: any) => {

                    const result = await response.json();

                    this.billingType = '';
                    this.productSelected = null;
                    window.open(result.checkoutUrl);
                });
            }
        },
        mounted() {

            this.$root.header = 'Upgrade your account'
            this.$root.activeMenu = '';

            let localUser = localStorage.getItem('user');

            if (localUser !== null) {
                this.user = JSON.parse(localUser);
            }

            ApiRequest('GET', '/product').then (async (response) => {

                this.products = await response.json();
                this.loaded = true;
                this.productSelected = this.user.subscription.product;
                this.billingType = this.user.company.subscriptionSettings.billing;
            });
        }
    })

export default class Upgrade extends Vue{}

