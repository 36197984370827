

import * as jsPlumbBrowserUI from "@jsplumb/browser-ui";
import {Options, Vue} from "vue-class-component";
import FlowBuilder from "@/components/Flowbuilder/Builder/FlowBuilder";
import FlowTransformer from "@/components/Flowbuilder/Builder/FlowTransformer";
import {reactive} from "vue";
import ComponentViewFactory from "@/components/Flowbuilder/Factory/ComponentViewFactory";
import FlowDrag from "@/components/Flowbuilder/Ui/DragDrop/FlowDrag";
import WorkflowExport from "@/components/Flowbuilder/Builder/WorkflowExport";
import ApiRequest from "@/api/ApiRequest";
import CreateComponent from "@/components/Flowbuilder/Component/CreateComponent.vue";
import {toast} from "vue3-toastify";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import PathResolver from "@/components/Flowbuilder/Helper/PathResolver";
import MappingCollectionTransformer from "./MappingTree/MappingCollectionTransformer";

@Options({
    props: {
        workflow: Object,
        apps: Array
    },
    data() {
        return {
            skey:1,
            listeners: {
                updateComponent: null,
                editComponent: null,
                addComponent: null,
                createComponent: null,
                saveTrigger: null,
                createBetweenComponent: null,
            },
            editComponent: null,
            sidebar: {
                active: false,
                component: Object,
                view: Vue,
                collectedData: [],
                parentComponent: {},
                position: 0,
                path: {}
            },
            collection: reactive([]),
            mappingCollection: []
        }
    },
    mounted() {

        this.instance = jsPlumbBrowserUI.newInstance({
            elementsDraggable: false,
            container: this.$refs["plumb-canvas"],
        });

        this.instance.importDefaults({
            connectionsDetachable: false,
        });

        // builder is responsible for drawing everything on the canvas
        // here it is only instantiated
        const builder: FlowBuilder = new FlowBuilder(this.instance, this.workflow.id);

        const transformer: FlowTransformer = new FlowTransformer(this.$props.apps);

        // create the trigger (ViewComponent)
        const trigger = transformer.trigger(this.workflow);

        // FlowTransformer will transform the raw workflow object from the API
        // in usable ViewComponent (Vue app wrappers) objects. here it is only instantiated
        // no HTML is generated
        const componentCollection = transformer.transform(
            this.workflow.steps
        );

        this.collection.push(trigger);
        this.collection = this.collection.concat(componentCollection);

        // generates all the HTML en lines
        builder.loop(this.collection);

        const jsPlumbContainer: HTMLElement = document.getElementById('jsplumb-canvas') as HTMLElement;

        this.listeners.updateComponent = (e: any) => {
            jsPlumbContainer.innerHTML = '';
            builder.loop(this.collection);
            this.sidebar.active = false;
            console.log('update')
        };

        this.listeners.addComponent = (e: any) => {

            const pathResolver = new PathResolver();
            const parent = pathResolver.getParentChildNodeCollection(e.detail.path, this.collection);

            parent.splice(e.detail.position, 0, e.detail.component);

            jsPlumbContainer.innerHTML = '';
            builder.loop(this.collection);
            this.sidebar.active = false;

            console.log('add')
        };

        this.listeners.createComponent = async (e: any) => {

            const mct = new MappingCollectionTransformer(
                this.collection
            );

            // assign data
            this.sidebar.view = CreateComponent;
            this.sidebar.collectedData = mct.getTree(
                e.detail.path
            );
            this.sidebar.component = {};
            this.sidebar.active = true;
            this.sidebar.parentComponent = e.detail.parentComponent;
            this.sidebar.currentCollection = e.detail.currentCollection;
            this.sidebar.position = e.detail.position;
            this.sidebar.path = e.detail.path;

            console.log('create')
        };

        this.listeners.editComponent = (e: any) => {

            const component: ViewComponent = e.detail.component;
            const componentViewFactory: ComponentViewFactory = new ComponentViewFactory();

            const mct = new MappingCollectionTransformer(
                this.collection
            );

            this.sidebar.view = componentViewFactory.edit(component.getType())
            this.sidebar.component = component.getComponent();
            this.sidebar.workflow = this.$props.workflow;
            this.sidebar.currentCollection = this.collection;
            this.sidebar.active = true;
            this.sidebar.collectedData = mct.getTree(
                e.detail.path
            );
            this.sidebar.path = component.getPath();
            this.skey = (new Date()).getTime()
            console.log('edit')
        };

        this.listeners.removeComponent = (e: any) => {

            const pathResolver : PathResolver = new PathResolver();
            const parent = pathResolver.getParentChildNodeCollection(e.detail.path, this.collection);

            parent.splice(
                e.detail.position,
                1
            );

            jsPlumbContainer.innerHTML = '';
            builder.loop( this.collection);
        };

        this.listeners.saveTrigger = (e: any) => {
            this.workflow.trigger = e.detail.trigger;
            this.sidebar.active = false;
        };

        window.addEventListener('updateComponent', this.listeners.updateComponent);
        window.addEventListener('addComponent', this.listeners.addComponent);
        window.addEventListener('createComponent', this.listeners.createComponent);
        window.addEventListener('saveTrigger', this.listeners.saveTrigger)
        window.addEventListener('editComponent', this.listeners.editComponent)
        window.addEventListener('createBetweenComponent', this.listeners.createBetweenComponent)
        window.addEventListener('removeComponent', this.listeners.removeComponent)

        new FlowDrag('js-container', 'jsplumb-canvas')

    },

    beforeUnmount() {
        console.log('bye')
        window.removeEventListener('updateComponent', this.listeners.updateComponent);
        window.removeEventListener('addComponent', this.listeners.addComponent);
        window.removeEventListener('createComponent', this.listeners.createComponent);
        window.removeEventListener('saveTrigger', this.listeners.saveTrigger)
        window.removeEventListener('editComponent', this.listeners.editComponent)
        window.removeEventListener('createBetweenComponent', this.listeners.createBetweenComponent)
        window.removeEventListener('removeComponent', this.listeners.removeComponent)
    },
    methods: {
        saveWorkflow() {
            const exporter = new WorkflowExport(this.workflow, this.collection);
            const workflow = exporter.export();

            ApiRequest('POST', '/workflow/' + workflow.id, workflow).then (() => {
                toast.success('workflow saved')
            });
        },
        saveComponent() {
            this.sidebar.active = false;
        },
        closeSidebar() {
            this.sidebar.active = false;
        },
        runWorkflow(): void
        {
            const exporter = new WorkflowExport(this.workflow, this.collection);
            const workflow = exporter.export();

            ApiRequest('POST', '/workflow/' + workflow.id, workflow).then (() => {
                ApiRequest('POST', '/workflow/' + workflow.id + '/start', workflow).then ((response: any) => {
                    console.log(response);
                });
            });
        },
        publishWorkflow(): void
        {
            console.log('publish')
        }
    },
    beforeMount() {
        this.$root.header = this.workflow?.name;
    }
})

export default class Flow extends Vue{}
