import {AnchorSpec} from "@jsplumb/common";
import {BlankEndpoint, Endpoint, EndpointOptions} from "@jsplumb/core";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import {FlowchartConnector} from "@jsplumb/connector-flowchart";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";

export default class LineDraw
{
    instance : BrowserJsPlumbInstance;

    collection: ViewComponent[];
    parentComponent: ViewComponent | null;
    currentPositionInCollection: number;

    AnchorBottom: AnchorSpec = [0.5, 1, 0, 0.5];
    AnchorTop: AnchorSpec = [0.5, 0, 0, -1];
    AnchorLeft: AnchorSpec = [0, 0.5, -1, 0];
    AnchorRight: AnchorSpec = [1, 0.5, 1, 0];

    walkedPath: string;
    constructor(
        instance: BrowserJsPlumbInstance,
        collection: ViewComponent[],
        parentComponent: ViewComponent | null,
        currentPositionInCollection: number,
        walkedPath: string
    ) {
        this.instance = instance;
        this.collection = collection;
        this.parentComponent = parentComponent;
        this.currentPositionInCollection = currentPositionInCollection;
        this.walkedPath = walkedPath;
    }

    line(

        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {



        const s : AnchorSpec = this.AnchorBottom;
        const e : AnchorSpec = this.AnchorTop;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        const y = this.connector(e1, p1, null, false, '#169b74');
        this.instance.connect(y);
    }

    decisionTrue(
        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {

        const s : AnchorSpec = this.AnchorRight;
        const e : AnchorSpec = this.AnchorTop;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        this.instance.connect(this.connector(e1, p1, null, true, '#7148c4', false));
    }

    decisionFalse(
        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {

        const s : AnchorSpec = this.AnchorLeft;
        const e : AnchorSpec = this.AnchorTop;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        this.instance.connect(this.connector(e1, p1, null, true, '#1e81e5', false));
    }

    endloop(
        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {

        const positions = [this.AnchorLeft, this.AnchorLeft];

        this.connect(
            fromElement,
            BlankEndpoint.type,
            positions[0],
            toElement,
            BlankEndpoint.type,
            positions[1],
            toElement.getAttribute('component-label') ?? 'bar'
        );
    }

    startloop(
        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {

        //const positions = [[0.4, 1, 0, 0.5], this.AnchorRight];

        const s : AnchorSpec = this.AnchorLeft;
        const e : AnchorSpec = this.AnchorTop;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        this.instance.connect(this.connector(e1, p1, null, true, '#666'));
    }

    dotted1(
        fromElement: HTMLElement,
        toElement: HTMLElement
    ) {

        const s : AnchorSpec = this.AnchorLeft;
        const e : AnchorSpec = this.AnchorTop;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        this.instance.connect(this.connector(e1, p1, null, true, '#666', true, 0.3));
    }

    dotted2(
        fromElement: HTMLElement,
        toElement: HTMLElement,
        addOverlay: boolean
    ) {

        //const positions = [[0.4, 1, 0, 0.5], this.AnchorRight];

        const s : AnchorSpec = this.AnchorRight;
        const e : AnchorSpec = this.AnchorLeft;

        const e1: Endpoint = this.instance.addEndpoint(
            fromElement,
            this.createEndpoint(BlankEndpoint.type, s)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            toElement,
            this.createEndpoint(BlankEndpoint.type, e)
        );

        this.instance.connect(this.connector(e1, p1, '+', true, '#666', addOverlay));
    }

    connect(startRef: HTMLElement, startType: string, startPosition: AnchorSpec, endRef: HTMLElement, endType: string, endPosition: AnchorSpec, label = ''): void {
        const e1: Endpoint = this.instance.addEndpoint(
            startRef,
            this.createEndpoint(startType, startPosition)
        );

        const p1: Endpoint = this.instance.addEndpoint(
            endRef,
            this.createEndpoint(endType, endPosition)
        );

        this.instance.connect(this.connector(e1, p1, label));
    }

    createEndpoint(endpointType: string, anchor: AnchorSpec): EndpointOptions {
        return {
            endpoint: {
                type: endpointType,
                options: {
                    cssClass: 'foo'
                }
            },
            anchor: anchor,
            maxConnections: -1,
            enabled: false,
        };
    }

    connector(source: any, target: any, label: null | string = '+', dashed = false, arrowColor = '#009465', addOverLay = true, location = 0.5) {

        const overlays: any[] = [];

        if (addOverLay) {
            if (target.element.getAttribute('component-type') !== 'plus') {

                overlays.push(
                    {
                        type: "Label",
                        options: {
                            label: label === null ? '+' : label,
                            location: location,
                            cssClass: 'add-plus',
                            events: {
                                'click': () => {
                                    const e = new CustomEvent(
                                        'createComponent', {
                                            detail: {
                                                parentComponent: this.parentComponent,
                                                currentCollection: this.collection,
                                                position : this.currentPositionInCollection,
                                                path: this.walkedPath
                                            }
                                        });
                                    window.dispatchEvent(e);
                                }
                            }
                        }
                    }
                )
            }
        }

        const paintStyle: any = {
            stroke:arrowColor,
            strokeWidth:1.5
        };

        if (dashed) {
            paintStyle.dashstyle = "2 3"
        }

        return {
            source: source,
            target: target,
            paintStyle: paintStyle,
            connector: {
                type: FlowchartConnector.type,
                options: {
                    cornerRadius: 8,
                    gap: 3
                },
            },
            detachable: false,
            overlays: overlays
        };
    }
}