

import {Options, Vue} from "vue-class-component";
import Manual from "@/components/Flowbuilder/Component/Trigger/View/Edit/manual/Manual.vue";
import Schedule from "@/components/Flowbuilder/Component/Trigger/View/Edit/schedule/Schedule.vue";
import Webhook from "@/components/Flowbuilder/Component/Trigger/View/Edit/webhook/Webhook.vue";
import TriggerComponent from "@/components/Flowbuilder/Component/Trigger/TriggerComponent";

@Options({
    components: {Manual, Webhook, Schedule},
    props: {
        component: TriggerComponent,
        workflow: Object
    },
    data() {
        return {
            type: null
        }
    },
    methods: {
        saveWorkflow() {
            console.log('save')
        },
        resetTriggerType() {
            this.selectedTrigger = null;
        },
        selectTrigger(trigger: string) {
            this.type = trigger;
            //this.$emit('save', this.data)
        },
        triggerUpdate(trigger: any) {

            this.$props.component?.setType(trigger.type);

            let params = {};

            switch (trigger.type) {
            case 'schedule':
                params = trigger.schedule;
                break;
            case 'webhook':
                params = {request: trigger.parameters.request};
                break;
            }

            this.$props.component?.setParameters(params);

            const e = new CustomEvent('saveTrigger', {
                detail: {
                    trigger: trigger
                }
            });
            window.dispatchEvent(e);
        }
    },
    mounted() {
        this.type = this.$props.component.getType();
    }
})

export default class TriggerEdit extends Vue {}

