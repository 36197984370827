import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "div-tree" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NodeIterator = _resolveComponent("NodeIterator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "tree-label",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.toggle()))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass('fas fa-chevron-' + (this.collapsed ? 'right' : 'down'))
      }, null, 2),
      _createElementVNode("label", null, _toDisplayString(this.$props.mappingValueObject.getLabel() ?? ''), 1)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NodeIterator, {
        mappingValueObject: _ctx.mappingValueObject,
        data: this.$props.mappingValueObject.getData(),
        path: '',
        leafIsSelectable: this.$props.leafIsSelectable,
        collectionIsSelectable: this.$props.collectionIsSelectable,
        onNodeSelected: _ctx.nodeSelected,
        onCheckboxCreated: _ctx.checkboxCreated
      }, null, 8, ["mappingValueObject", "data", "leafIsSelectable", "collectionIsSelectable", "onNodeSelected", "onCheckboxCreated"])
    ], 512), [
      [_vShow, !this.collapsed]
    ])
  ]))
}