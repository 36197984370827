

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";

@Options({
    data() {
        return {
            recent: []
        }
    },
    beforeMount() {
        this.$root.header = 'Welcome'
        this.$root.activeMenu = 'home'

        ApiRequest('GET', '/dashboard/home').then(async (response: any) => {
            const result = await response.json();
            this.recent = result.recent;
        })
    },
    methods: {
        openWorkflow(workflowId: string): void
        {
            this.$router.push({
                name: 'WorkflowEditor',
                params: {
                    id: workflowId
                }
            })
        }
    }
})
export default class Home extends Vue{}

