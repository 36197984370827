

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, Select},
    props: {
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
    },
    data() {
        return {
            list: [],
            listCells: [],

            selected: {
                title: '',
                id: ''
            },
            value: {
                lookupMethod: 'cell',
                cell: '',
                column: '',
                query: ''
            }
        }
    },
    methods: {
        setCell(cell: string)
        {
            this.value.cell = cell;
            this.$emit('paramValue', JSON.stringify(this.value))
        },
        setColumn(column: string) {
            this.value.column = column;
        },
        setLookupMethod(method: string){

            if (method === 'cell') {
                this.value.column = '';
                this.value.query = '';
            }

            if (method === 'column') {
                this.value.cell = '';
            }

            this.value.lookupMethod = method;
        },
        pathSelected(path: string) {
            this.value.query = path;
            this.$emit('paramValue', JSON.stringify(this.value))
        },
        loadSheets() {

            let worksheetId: string | null = null;
            let spreadsheetId: string | null = null;

            for(const i in this.$props.parameters) {
                if (this.$props.parameters[i].name === 'worksheetId') {
                    worksheetId = this.$props.parameters[i].value;
                }
            }

            for(const i in this.$props.parameters) {
                if (this.$props.parameters[i].name === 'spreadsheetId') {
                    spreadsheetId = this.$props.parameters[i].value;
                }
            }

            let queryString = '?appAccountId=' + this.appAccountId + '&worksheetId=' + worksheetId + '&fileId=' + spreadsheetId;


            ApiRequest('GET', '/google/spreadsheet/data' + queryString).then(async (response: any) => {

                const result = await response.json();

                this.list = [];
                this.listCells = [];

                for (const i in result) {
                    const id = '$' + result[i].col + ':' + result[i].row;
                    const value = id + '(' + result[i].value + ')';
                    this.listCells.push({
                        id: id,
                        title: value,
                        description: value
                    })
                }

                for (const i in result) {
                    const colName = '$' + result[i].col;

                    if (parseInt(result[i].row) === 1) {
                        this.list.push({
                            id: colName,
                            title: colName,
                            description: colName
                        });
                    }
                }

                this.selected.title = '';
                this.selected.id = '';
            })
        }
    }
})


export default class GoogleSheetsLookup extends Vue {}

