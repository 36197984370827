import Component from "@/components/Flowbuilder/Component/Component";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";

export default class FunnelComponent extends Component
{
    private id: string | null;

    private field: string;

    private value: string;

    private comparison: string;

    private childNodes: ViewComponent[] = [];

    constructor(id: string | null, field: string, value = '', comparison = '') {
        super('');
        this.id = id;
        this.field = field;
        this.value = value;
        this.comparison = comparison;
    }

    getField(): string
    {
        return this.field;
    }

    setField(field: string): void
    {
        this.field = field;
    }

    setValue(value: string): void
    {
        this.value = value;
    }

    getValue(): string
    {
        return this.value;
    }

    setComparison(comparison: string): void
    {
        this.comparison = comparison;
    }

    getComparison(): string
    {
        return this.comparison;
    }
}
