

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import CondensedSetComponent from "@/components/Flowbuilder/Component/CondensedSet/CondensedSetComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import App from "@/components/Flowbuilder/Apps/App";
import ChannelParameters from "@/components/Flowbuilder/Component/Channel/View/Edit/Parameters/ChannelParameters.vue";
import RequestBody from "@/components/Flowbuilder/Component/Channel/View/Edit/RequestBody/RequestBody.vue";
import ApiRequest from "@/api/ApiRequest";
import ChannelComponent from "../../../Channel/ChannelComponent";
import LoopComponent from "@/components/Flowbuilder/Component/Loop/LoopComponent";
import ActionResolver from "@/components/Flowbuilder/Helper/ActionResolver";

@Options({
    computed: {
        ChannelComponent() {
            return ChannelComponent
        }
    },
    emits: ['back'],

    components: {
        RequestBody,
        ChannelParameters,
        MappingTree
    },
    props: {
        component: null,
        collectedData: Array,
        currentCollection: Array
    },
    data() {
        return {
            field: '',
            channels: [],
            component: null,
            parameters: [],
            account: null,
            requestBody: {},
            requestSchema: {},
            action: null,
            condensedSet: {}
        }
    },
    mounted() {
        this.collectionIterator(this.$props.currentCollection)
    },
    methods: {
        save() {

            const condensedSet : CondensedSetComponent = this.$props.component as CondensedSetComponent;
            condensedSet.setCondensedSet(this.condensedSet);

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: condensedSet,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                }
            });

            window.dispatchEvent(e);

        },
        create() : void
        {
            const component : CondensedSetComponent = new CondensedSetComponent(
                null,
                this.condensedSet
            )

            const view : ViewComponent = new ViewComponent(ComponentType.CONDENSED_SET, null, component);
            this.$emit('saveComponent', view);
        },
        execute(): void {

            const step = {
                componentType: ComponentType.CHANNEL,
                appAccount: this.component.getAppAccount(),
                mapping: this.$refs.requestBody.getRequestBody(),
                parameters: this.parameters,
                action: this.component.getOperationId(),
                componentReferenceId: this.component.getComponentReferenceId()
            }

            ApiRequest('POST', '/channel/execute', step).then(async (response: any) => {
                const result = await response.json();

                if (response.status === 201 || response.status === 200) {
                    const parent = document.getElementById('condensed-response') as HTMLElement;
                    this.displayResponse(result, parent);
                } else {
                    console.log('An error occurred while fetching a response')
                }
            });
        },
        displayResponse(object: any, parent: HTMLElement, path = '') {

            const concat = path === '' ? '' : '.';

            for (const i in object) {

                const id = (Date.now() + Math.random()) + '_' + i;
                const div = document.createElement('div');

                const checkbox = document.createElement('input');
                checkbox.id = id;
                checkbox.type = 'checkbox';
                checkbox.onmouseup = () => {
                    this.updateCondensedSet(
                        path + concat + i
                    )
                }

                const label = document.createElement('label') as HTMLLabelElement;
                label.innerHTML = i;
                label.htmlFor = id;
                label.onmouseup = () => {
                    this.updateCondensedSet(
                        path + concat + i
                    )
                }

                div.appendChild(checkbox);
                div.appendChild(label);

                parent.appendChild(div);

                if (typeof object[i] === 'object') {
                    this.displayResponse(object[i], div, path + concat + i);
                }
            }
        },
        updateCondensedSet(path: string): void
        {
            let condensedSet : any = {};

            // add
            if (!this.condensedSet.hasOwnProperty(path)) {
                this.condensedSet[path] = {};
                return;
            }

            // remove
            for (const i in this.condensedSet) {
                if (i !== path) {
                    condensedSet[i] = {};
                }
            }


            this.condensedSet = condensedSet;
        },
        back() {
            this.$emit('back');
        },
        selectComponent(component: ChannelComponent): void
        {
            this.component = component;

            const resolver : ActionResolver = new ActionResolver();
            const currentAction : AppAction | null = resolver.resolve(component);
            const actionRequestBody = currentAction?.getRequestBody();

            this.requestSchema = {};
            this.currentAction = currentAction;
            this.parameters = this.currentAction?.getParameters();

            if (actionRequestBody === null) {
                console.log('no request body schema present ')
                return;
            }

            const requestContentTypeKey = Object.keys(actionRequestBody.content)[0];

            if (actionRequestBody.content[requestContentTypeKey].length === 0) {
                this.move(2);
                return;
            }

            // pass everything in the content section, incl "schema"
            this.requestSchema = actionRequestBody.content[requestContentTypeKey];
        },
        collectionIterator(collection: any): void
        {
            collection.forEach((item: ViewComponent) => {
                if (item.getType() === ComponentType.CHANNEL) {
                    this.channels.push((item.getComponent() as ChannelComponent));
                }

                if (item.childNodes.length > 0) {
                    this.collectionIterator(item.childNodes);
                }
            })
        },
        label(app: App, operationId: string): string
        {
            let currentAction : AppAction | null = null;
            let label = '';

            for (let i in app.getActions()) {
                if (app.getActions()[i].getOperationId() === operationId) {
                    currentAction = app.getActions()[i];
                    break;
                }
            }

            label = operationId;

            if (currentAction?.getSummary() !== '') {
                label = currentAction?.getSummary() ?? '';
            }

            if (currentAction?.getDescription() !== '' ) {
                label = currentAction?.getDescription() ?? '';
            }

            return label;
        }
    }
})


export default class CreateCondensedSet extends Vue{}
