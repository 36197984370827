

import { useRoute } from 'vue-router'
import ApiRequest from "@/api/ApiRequest";
import {Options, Vue} from "vue-class-component";

@Options({
    data() {
        return {
            confirmed: false,
            error: false,
            errorMessage: ''
        }
    },
    mounted() {

        const signature = useRoute().params.signature;

        ApiRequest('POST', '/account/registration/confirm', {
            signature: signature}
        ).then(async (response: any) => {

            const result = await response.json();

            if (result.error) {
                this.error = true;
                this.errorMessage = result.messages[0];
            } else {
                this.confirmed = true;
            }
        })
    }
})
export default class RegisterConfirm extends Vue {}
