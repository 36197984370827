

import {Options, Vue} from "vue-class-component";
import ChannelAppAccount from "@/components/Flowbuilder/Component/Channel/View/Edit/AppAccount/ChannelAppAccount.vue";
import ChannelAction from "@/components/Flowbuilder/Component/Channel/View/Edit/Action/ChannelAction.vue";
import App from "@/components/Flowbuilder/Apps/App";
import AppAccount from "@/models/AppAccount";
import AppAccountService from "@/service/AppAccountService";
import CreateChannelToolbar from "@/components/Flowbuilder/Component/Channel/View/Edit/CreateChannelToolbar.vue";
import ChannelParameters from "@/components/Flowbuilder/Component/Channel/View/Edit/Parameters/ChannelParameters.vue";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import AppParameterMerge from "@/components/Flowbuilder/Builder/Helper/AppParameterMerge";
import RequestBody from "@/components/Flowbuilder/Component/Channel/View/Edit/RequestBody/RequestBody.vue";
import TestChannel from "@/components/Flowbuilder/Component/Channel/View/Edit/Test/TestChannel.vue";
import CreateCondensedSet from "@/components/Flowbuilder/Component/CondensedSet/View/Edit/CreateCondensedSet.vue";
import {ref} from "vue";

@Options({
    props: {
        apps: Array,
        responseMap: Array,
        collectedData: Array,
        parentComponent: {},
        path: {},
        component: null
    },
    emits: ['saveComponent'],
    data() {

        const appAccount: AppAccount | null = null;

        return {
            // holds the action from the yaml file
            actions: [],
            app: {},
            appAccount: appAccount,
            requestSchema: {},
            parameters: ref([]),
            operationId: null,
            requestBody: {},
            currentCarouselIndex: 0,
            isEditing: false,
            configuration: null,
            schemaSet: false,
            customYaml: {},
            tabs: {
                account: {
                    open: true
                },
                action: {
                    open: false
                },
                mapping: {
                    open: false
                },
                parameters: {
                    open: false
                }
            }
        }
    },
    components: {
        CreateCondensedSet,
        TestChannel, RequestBody, ChannelParameters, CreateChannelToolbar, ChannelAction, ChannelAppAccount},
    beforeMount() {
        if (typeof this.$props.component !== 'undefined') {

            const channel : ChannelComponent = this.$props.component as ChannelComponent;
            this.requestBody = channel.getRequestBody()

            this.appAccountSelected(channel.getAppAccount())

            // will convert the workflow parameters to
            // an array of AppParameter[]
            this.operationIdSelected(channel.getOperationId())

            this.operationId = channel.getOperationId();
            this.app = channel.getApp();
        }
    },
    mounted() {

        if (typeof this.$props.component !== 'undefined') {

            const appAccountService: AppAccountService = new AppAccountService();
            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            this.appAccountSelected(
                appAccountService.createFromResponse(channel.getAppAccount())
            );

            this.operationIdSelected(
                channel.getOperationId()
            )
        }

        this.isEditing = true;
        this.currentCarouselIndex = -1;
        this.move(0);
    },
    methods: {
        storeYamlResult(yaml: any) {
            this.customYaml = yaml;
        },
        back() {
            this.$emit('back');
        },
        appSelected(app: App): void
        {
            this.app = app;
        },
        move(index: number) {

            if (index === this.currentCarouselIndex || !this.isEditing) {
                return;
            }

            const carouselItems = document.getElementsByClassName('carousel-item');

            for (let i = 0; i < carouselItems.length; i++) {
                const carouselItem = carouselItems[i] as HTMLElement;
                carouselItem.style.display = i === index ? 'block' : 'none';
            }

            this.currentCarouselIndex = index;
        },
        next() {

            const itemCount = document.getElementsByClassName('carousel-item').length;

            if (this.currentCarouselIndex < itemCount  - 1) {
                this.move(this.currentCarouselIndex + 1);
            }
        },
        prev() {
            if (this.currentCarouselIndex >= 1) {
                this.move(this.currentCarouselIndex - 1);
            }
        },
        saveParameters(parameters: any[]): void
        {
            this.parameters = parameters;
            this.move(3);
        },
        toggleDisplayState(tab: string) {
            this.tabs[tab].open = !this.tabs[tab].open;
        },
        saveComponent() {

            if (this.appAccount === null) {
                console.log('AppAccount is required before saving');
                return;
            }

            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            channel.setApp(this.app);


            this.appAccount.configuration.forEach((config: any, index:number) => {
                this.appAccount.configuration[index] = {
                    key: config.key,
                    id: config.id,
                    value: config.value,
                }
            });

            channel.setOperationId(this.operationId);
            channel.setAppAccount(this.appAccount);

            if (this.customYaml !== '') {
                channel.setCustomResponse(this.customYaml);
            }

            if (typeof this.$refs.requestBody !== 'undefined') {
                channel.setRequestBody(this.$refs.requestBody.getRequestBody());
            }

            if (typeof this.$refs['channelParameters'] !== 'undefined') {
                channel.setParameters(this.$refs['channelParameters'].getParameters());
            }

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: channel,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                    path: this.$props.parth,
                }
            });

            window.dispatchEvent(e);
        },
        createComponent() {

            if (this.appAccount === null) {
                console.log('AppAccount is required before saving');
                return;
            }

            if (typeof this.appAccount !== 'undefined') {
                this.appAccount.getConfiguration().forEach((config: any, index: number) => {
                    this.appAccount.getConfiguration()[index] = {
                        key: config.key,
                        id: config.id,
                        value: config.value,
                    }
                });
            }

            const channel : ChannelComponent = new ChannelComponent(
                null,
                this.app,
                this.operationId,
                this.appAccount,
                this.mapping,
                this.$refs['channelParameters'].getParameters(),
                '',
                this.customYaml
            )

            const requestBody = typeof this.$refs.requestBody !== 'undefined'
                ? this.$refs.requestBody.getRequestBody()
                : null;

            channel.setRequestBody(requestBody);
            channel.setOperationId(this.operationId);
            channel.setAppAccount(this.appAccount);
            channel.setParameters(this.$refs['channelParameters'].getParameters());

            const view : ViewComponent = new ViewComponent(ComponentType.CHANNEL, null, channel);
            this.$emit('saveComponent', view);
        },
        appAccountSelected(appAccount: AppAccount): void {

            this.appAccount = appAccount;

            this.$props.apps.getApps().forEach((app: App) => {

                if (app.getSlug() === appAccount.getAppName()) {
                    this.app = app;
                    const collection: any = [];

                    app.getActions().forEach((action: any) => {

                        if (typeof action.operationId !== 'undefined' && action.operationId !== '') {

                            let title = action.operationId;

                            if (typeof action.summary !== 'undefined' && action.summary !== '') {
                                title = action.summary;
                            }

                            if (typeof action.description !== 'undefined' && action.description !== '') {
                                title = action.description;
                            }

                            collection.push({
                                id: action.operationId,
                                title: title
                            })
                        }
                    })

                    this.actions = collection;
                    this.move(1);
                }
            })
        },
        operationIdSelected(operationId: string): void
        {
            if (typeof this.app === 'undefined') {
                console.log('app is undefined')
                return;
            }

            // assign the operation id selected by the user
            this.operationId = operationId;

            // create new empty ChannelComponent
            let channel : ChannelComponent | null | undefined = null;

            // channel is not null, we are in edit mode
            if (this.$props.component !== null && typeof this.$props.component !== 'undefined') {
                channel = this.$props.component as ChannelComponent;
                channel.setOperationId(operationId);
            }

            let cAction : AppAction = new AppAction('');

            this.app.getActions().forEach((action: AppAction) => {
                if (action.getOperationId() === operationId) {
                    cAction = action;
                }
            });

            this.parameters = (new AppParameterMerge()).merge(
                channel?.getParameters() ?? [],
                cAction.getParameters()
            )

            //this.parameters = cAction.getParameters();

            const actionRequestBody = cAction.getRequestBody();

            if (actionRequestBody === null) {
                console.log('no request body schema present for ' + operationId)
                this.move(2);
                return;
            }

            const requestContentTypeKey = Object.keys(actionRequestBody.content)[0];

            if (actionRequestBody.content[requestContentTypeKey].length === 0) {
                this.move(2);
                return;
            }

            // pass everything in the content section, incl "schema"
            this.requestSchema = actionRequestBody.content[requestContentTypeKey];
            this.schemaSet = true;
        },
    }
})

export default class CreateChannel extends Vue{}

