

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import createStore from "@/store";

@Options({
    data() {
        return {
            apps: [],
            workflow: null,
            isRunning: false
        }
    },
    methods: {
        getAppImage(appName: string): string
        {
            console.log(this.apps)
            for (const i in this.apps) {
                if (this.apps[i].appName === appName) {
                    return this.apps[i].image;
                }
            }
            return '';
        },
        formatBytes(bytes: number) {
            if (bytes < 1000) {
                return bytes + 'b';
            }

            if (bytes > 1000) {
                return Math.round(bytes / 1000) + 'kb';
            }
        },
        getBytesSend(run: any) {
            return this.formatBytes(run.bytesSent);
        },
        getBytesReceived(run: any) {
            return this.formatBytes(run.bytesReceived);
        },
        getExecutionTime(run: any) {
            return run.executionTime;
        },
        getRequests(run: any): number {

            let channels = 0;

            run.logs.forEach((log: any) => {
                if (log.indexOf('channel') !== -1) {
                    channels++;
                }
            })

            return channels;
        },
        run() {

            const user = createStore.getters.getSignedInUser;
            this.isRunning = true;
            ApiRequest(
                'POST',
                '/workflow/' + this.workflow.id + '/start',
                null,
                [{'access-token': user.apiToken}]
            ).then(() => {
                this.isRunning = false;
            });
        },
        editor() {
            this.$router.push({
                name: 'WorkflowEditor',
                params: {
                    id: this.workflow.id
                }
            })
        },
        edit() {
            this.$router.push({
                name: 'WorkflowEdit',
                params: {
                    id: this.workflow.id
                }
            })
        }
    },
    beforeMount() {
        let parts = document.location.href.split('/');

        ApiRequest('GET', '/app').then(async (response:any) => {
            this.apps = await response.json();
        })

        ApiRequest('GET', '/workflow/' + parts[parts.length -1]).then (async (response:any) => {
            this.workflow = await response.json();
            this.$root.header = 'Workflow usage: ' + this.workflow?.name;
            this.loaded = true;
        });
    },
    mounted() {
        this.$root.activeMenu = 'workflow-overview';
    }
})

export default class WorkflowView extends Vue{}

