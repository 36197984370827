import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "node-iterator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NodeCheckbox = _resolveComponent("NodeCheckbox")!
  const _component_NodeIterator = _resolveComponent("NodeIterator", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.data, (map, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_NodeCheckbox, {
          selectedValue: this.$props.selectedValue,
          value: this.$props.path + '.' + String(index),
          id: this.$props.path + '.' + String(index),
          label: String(index),
          isCollection: typeof map === 'object',
          mappingValueObject: this.$props.mappingValueObject,
          onNodeSelected: this.nodeSelected,
          onCheckboxCreated: this.checkboxCreated,
          leafIsSelectable: this.$props.leafIsSelectable,
          collectionIsSelectable: this.$props.collectionIsSelectable
        }, null, 8, ["selectedValue", "value", "id", "label", "isCollection", "mappingValueObject", "onNodeSelected", "onCheckboxCreated", "leafIsSelectable", "collectionIsSelectable"]),
        (typeof map === 'object')
          ? (_openBlock(), _createBlock(_component_NodeIterator, {
              key: 0,
              mappingValueObject: this.$props.mappingValueObject,
              leafIsSelectable: this.$props.leafIsSelectable,
              collectionIsSelectable: this.$props.collectionIsSelectable,
              isCollection: true,
              path: this.$props.path + '.' + String(index) + '[]',
              data: map,
              onNodeSelected: this.nodeSelected,
              onCheckboxCreated: this.checkboxCreated
            }, null, 8, ["mappingValueObject", "leafIsSelectable", "collectionIsSelectable", "path", "data", "onNodeSelected", "onCheckboxCreated"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}