


import {Options, Vue} from "vue-class-component";
import CollectionLookupComponent from "@/components/Flowbuilder/Component/CollectionLookup/CollectionLookupComponent";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import Select from "@/components/Elements/Select.vue";
import PathResponseTransformer from "@/components/Flowbuilder/MappingTree/PathResponseTransformer";
import MappingValue from "@/components/Flowbuilder/MappingTree/ValueObject/MappingValue";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";

@Options({
    components: {Select, MappingTree},
    props: {
        collectedData: Array,
        component: CollectionLookupComponent,
        currentCollection: Array
    },
    data() {
        return {
            collection: '',
            field: '',
            match: '',
            responseObject: [],
            responseData: {}
        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined' && this.$props.component !== null) {
            this.collection = this.$props.component?.getCollection();
            this.match = this.$props.component?.getMatch();
            this.field = this.$props.component?.getField();
        }
    },
    methods: {
        pathSelected(path: string, mappingValueObject: ValueObjectInterface): void
        {
            const transformer : PathResponseTransformer = new PathResponseTransformer();
            const data = transformer.transform(this.$props.currentCollection, mappingValueObject, path);
            this.responseObject = [new MappingValue('Collection data', data, 'channel', '', [])];
            this.responseData = data;
        },
        fieldSelected(path: string) {
            this.field = path.replace(/\{|\}|\./g, '');
        },
        saveLookup() : void
        {
            if (typeof this.$props.component !== 'undefined') {

                this.$props.component.setCollection(this.collection);
                this.$props.component.setField(this.field);
                this.$props.component.setMatch(this.match);
                this.$props.component.setResponse(this.responseData);

                const e = new CustomEvent('updateComponent', {
                    detail: {
                        component: this.$props.component,
                        parentComponent: this.$props.parentComponent,
                        currentCollection: this.$props.currentCollection,
                    }
                });

                window.dispatchEvent(e);

            } else {

                const lookup: CollectionLookupComponent = new CollectionLookupComponent(this.collection, this.field, this.match, this.responseData);
                const view: ViewComponent = new ViewComponent(ComponentType.COLLECTION_LOOKUP, null, lookup, 0, 0, [])

                this.$emit('saveComponent', view)
            }
        }
    }
})

export default class CreateCollectionLookup extends Vue{}
