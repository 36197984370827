import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mapping-tree" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "search-container" }
const _hoisted_5 = ["value", "name"]
const _hoisted_6 = {
  key: 1,
  class: "tree"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeNode = _resolveComponent("TreeNode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (this.$props.label !== '')
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(this.$props.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("input", {
          value: _ctx.targetValue,
          name: this.id,
          type: "text",
          class: "form-control search-box",
          placeholder: "type or pick",
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:targetValue', $event.target.value)))
        }, null, 40, _hoisted_5),
        (this.$props.displayButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args))),
              class: "map-button"
            }, "map"))
          : _createCommentVNode("", true)
      ]),
      (this.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.collectedData, (mappingValueObject, index) => {
              return (_openBlock(), _createBlock(_component_TreeNode, {
                key: index,
                leafIsSelectable: this.$props.leafIsSelectable,
                collectionIsSelectable: this.$props.collectionIsSelectable,
                mappingValueObject: mappingValueObject,
                onPathPropertySelected: _ctx.pathPropertySelected,
                onCheckboxCreated: _ctx.registerCheckbox
              }, null, 8, ["leafIsSelectable", "collectionIsSelectable", "mappingValueObject", "onPathPropertySelected", "onCheckboxCreated"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}