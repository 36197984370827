

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import Select from "@/components/Elements/Select.vue";
import TableRules from "@/components/Flowbuilder/Component/Decision/View/Edit/TableRules.vue";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";
import PathResponseTransformer from "@/components/Flowbuilder/MappingTree/PathResponseTransformer";
import MappingValue from "@/components/Flowbuilder/MappingTree/ValueObject/MappingValue";

@Options({
    components: {TableRules, Select, MappingTree},
    emits: ['saveRule'],
    props: {
        collectedData: Array,
        rules: Array,
        currentCollection: Array,
        dataTree: Object,
    },
    data() {
        return {
            collection: '',
            field: '',
            comparison: '',
            value: '',
            comparisonOptions: [
                {id: 'eq', title: 'equals'},
                {id: 'ne', title: 'not equals'},
                {id: 'lt', title: 'lesser than'},
                {id: 'gt', title: 'greater than'},
                {id: 'ct', title: 'contains'},
            ],
            responseObject: []
        }
    },
    methods: {
        setComparison(comparison: string): void {
            this.comparison = comparison;
        },
        saveRule(): void {
            this.$emit('saveRule', this.collection, this.field, this.value, this.comparison)
            this.value = '';
            this.field = '';
        },
        removeRule(index: number): void
        {
            this.$emit('removeRule', index)
        },
        pathSelected(path: string, mappingValueObject: ValueObjectInterface): void
        {
            const transformer : PathResponseTransformer = new PathResponseTransformer();
            const data = transformer.transform(this.$props.currentCollection, mappingValueObject, path);
            this.responseObject = [new MappingValue('Collection data', data, 'channel', '', [])];
        },
        fieldSelected(path: string) {
            this.field = path.replace(/\{|\}|\./g, '');
        }
    }
})

export default class DecisionRuleCollection extends Vue{}
