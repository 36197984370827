

import createStore from "../../store";
import ApiRequest from "../../api/ApiRequest";
import {Options, Vue} from "vue-class-component";

@Options({
    data() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        login() {
            ApiRequest(
                'POST',
                '/account/authenticate',
                {
                    email: this.email,
                    password: this.password,
                }
            ).then(async (response: any) => {
                createStore.commit('setSignedInUser', await response.json());
                this.$router.push({name: 'Home'})
            });
        }
    },
    emits:["userUpdate"],
    mounted() {
        this.$refs.inputEmail.focus();
    }
})


export default class Login extends Vue{}

