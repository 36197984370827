
import ComponentDraw from "@/components/Flowbuilder/Ui/PlumbDrawer/ComponentDraw";
import {App} from "vue";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import LineDraw from "@/components/Flowbuilder/Ui/Line/LineDraw";
import ContainerHelper from "@/components/Flowbuilder/Builder/Container/ContainerHelper";
import Plus from "@/components/Flowbuilder/Builder/Plus";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import Component from "@/components/Flowbuilder/Component/Component";

export default class FlowBuilder {

    static readonly DEFAULT_DISTANCE_Y = 130;

    static readonly DEFAULT_DISTANCE_DECISION_FALSE_X = 260;

    static readonly DEFAULT_DISTANCE_DECISION_TRUE_X = 130;

    static readonly DEFAULT_DISTANCE_LOOP_X = 250;

    helper: ComponentDraw;

    instance :BrowserJsPlumbInstance;

    collectionFlattened: ViewComponent[] = [];

    workflowId: string;

    useCustomPositions = false;

    flowControlTypes: any = {
        [ComponentType.DECISION]: true,
        [ComponentType.DECISION_TRUE]: true,
        [ComponentType.DECISION_FALSE]: true,
        [ComponentType.LOOP_LABEL]: true,
        [ComponentType.LOOP]: true,
        [ComponentType.TRIGGER]: true,
    };

    constructor(instance :BrowserJsPlumbInstance, workflowId: string) {
        this.instance = instance;
        this.helper = new ComponentDraw(this.instance)
        this.workflowId = workflowId;
    }

    loop(
        collection: ViewComponent[],
        startOffsetX = 0,
        startOffsetY = 0,
        parentComponent: ViewComponent | null = null,
        x: string[] = [],
        depth = 0,
        decision : null | string = null,
        walkedPath = ''
    ) {

        const concat = walkedPath === '' ? '' : '.';

        const containerHelper: ContainerHelper = new ContainerHelper(this.instance, this.workflowId);
        const jsPlumbContainer: HTMLElement = document.getElementById('jsplumb-canvas') as HTMLElement;
        const path = x;
        let lineDraw : LineDraw;

        for (let i = 0; i < collection.length; i++) {
            lineDraw = new LineDraw(this.instance, collection, parentComponent, i, walkedPath + concat + i)
            const id:string  = (Date.now() + Math.random()) + (':' + collection[i].getType());

            path.push(id);

            startOffsetY = containerHelper.calculateOffsetY(collection[i].getType(), startOffsetY);
            startOffsetX = containerHelper.calculateOffsetX(collection[i].getType(), startOffsetX);

            // activates custom positioning, looks weird with insert channel in between
            // other channels, technically it works good
            if (this.useCustomPositions) {
                startOffsetX = collection[i].getPositionX() === 0
                    ? startOffsetX
                    : collection[i].getPositionX();

                startOffsetY = collection[i].getPositionY() === 0
                    ? startOffsetY
                    : collection[i].getPositionY();
            }

            this.collectionFlattened.push(collection[i]);

            collection[i].setId(id);
            collection[i].setPath(this.clone(path));
            collection[i].setPositionY(startOffsetY);
            collection[i].setPositionX(startOffsetX);

            // create HTML container for mounting
            collection[i].setHtmlContainer(
                containerHelper.createContainer(
                    collection[i],
                    '',
                    i,
                    walkedPath + concat + i
                )
            );

            jsPlumbContainer.appendChild(
                collection[i].getHtmlContainer()
            );

            // create the view
            const view: App = containerHelper.createView(collection[i])

            view.mount(
                collection[i].getHtmlContainer()
            );

            if (collection[i].getType() === ComponentType.DECISION_FALSE ) {

                path.pop();
                path.pop();

                const id2 = (Date.now() + Math.random()) + (':' + collection[i].getType());
                path.push(id2);
                collection[i].setId(id2);

                this.collectionFlattened.push(collection[i]);
                collection[i].setPath(path)
            }

            // iterate through childNodes of component
            if (collection[i].getChildNodes().length > 0 || collection[i].getType() === ComponentType.LOOP || collection[i].getType() === ComponentType.DECISION_FALSE || collection[i].getType() === ComponentType.DECISION_TRUE) {

                let nextOffsetX = collection[i].getPositionX();
                let nextOffsetY = collection[i].getPositionY();

                if (collection[i].getType() === ComponentType.LOOP) {
                    nextOffsetX -= FlowBuilder.DEFAULT_DISTANCE_LOOP_X;
                    nextOffsetY -= 60;
                }

                if (collection[i].getType() === ComponentType.DECISION_TRUE || collection[i].getType() === ComponentType.DECISION_FALSE) {
                    nextOffsetY -= 30;
                    decision = collection[i].getType();
                }

                this.loop(collection[i].getChildNodes(), nextOffsetX, nextOffsetY, collection[i], this.clone(path),  (depth + 1 + i), decision, walkedPath + concat + i);
            }

            // lines
            if (i === 0 && parentComponent?.getType() === ComponentType.LOOP) {
                // draw starting line of loop component
                const tmpContainer =  document.createElement('div') as HTMLElement;

                tmpContainer.style.position = 'absolute';
                tmpContainer.style.left = (collection[i].getPositionX() + 130) + "px";
                tmpContainer.style.top = (collection[i].getPositionY() - 56) + "px";

                jsPlumbContainer.appendChild(
                    tmpContainer
                );

                const tmp = new Component;
                const view: App = containerHelper.createView(
                    new ViewComponent(ComponentType.LOOP_LABEL, null, tmp, 0, 0)
                )

                view.mount(
                    tmpContainer
                );

                lineDraw.dotted1(tmpContainer as HTMLElement, collection[i].getHtmlContainer());
                lineDraw.dotted2(tmpContainer as HTMLElement, parentComponent.getHtmlContainer(), false);
            }

            if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && collection[i]?.getType() === ComponentType.LOOP && i === 0) {
                // draw starting line of loop component
                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer());
            }

            if (i > 0 && parentComponent?.getType() !== ComponentType.DECISION) {
                lineDraw.line(collection[i - 1].getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer());
            }

            if (collection[i].getType() === ComponentType.DECISION_FALSE && parentComponent?.getType() === ComponentType.DECISION) {
                lineDraw.decisionFalse(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            if (collection[i].getType() === ComponentType.DECISION_TRUE && parentComponent?.getType() === ComponentType.DECISION) {
                lineDraw.decisionTrue(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            const isAllowed = !this.flowControlTypes.hasOwnProperty(collection[i].getType());

            // in the decision false/true lane, connects only the first component to the parent
            if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && (isAllowed) && i === 0) {
                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            // loop breakers
            if (collection[i].getType() === ComponentType.DECISION) {
                // prevent creating a plus in the wrong lane by stopping all iteration
                // further iteration is done in the lanes
                return;
            }
        }

        const lastComponent : ViewComponent | null = collection.length === 0 ? null : collection[collection.length -1];
        const addToPath = walkedPath + concat + collection.length;

        lineDraw = new LineDraw(this.instance, collection, parentComponent, collection.length, addToPath)

        if (parentComponent?.getType() !== ComponentType.DECISION ) {

            if (parentComponent?.getType() === ComponentType.LOOP && lastComponent === null) {

                const plus = (new Plus(collection, startOffsetX + 14, startOffsetY + 40, '', parentComponent, addToPath)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.startloop(parentComponent.getHtmlContainer() as HTMLElement, plus);

            } else if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && collection.length === 0) {

                const plus = (new Plus(collection, startOffsetX, startOffsetY + FlowBuilder.DEFAULT_DISTANCE_Y - 60, '', parentComponent, addToPath)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, plus);

            } else if (collection.length > 0) {

                // endpoints
                const plus = (new Plus(collection, startOffsetX, startOffsetY - 20, '', parentComponent, addToPath)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.line(collection[collection.length - 1].getHtmlContainer() as HTMLElement, plus);
            }
        }
    }

    clone(path: string[]) : string[]
    {
        return JSON.parse(
            JSON.stringify(path)
        );
    }
}