export default class ResponseYamlTransformer
{
    export(object: any, contentType: string): any
    {
        const parse = (object: any, schema: any): any => {

            for (const i in object) {

                if (Object.prototype.toString.call(object[i]) === '[object Array]') {

                    const t = {};
                    schema[i] = {type: 'array', items: {type: 'object', properties: t}};
                    parse({fake: object[i][0]}, t);
                } else if (i === 'fake') {

                    parse(object.fake, schema)
                } else if (Object.prototype.toString.call(object[i]) === '[object Object]') {
                    const d = {}
                    schema[i] = {type: 'object', properties: d}
                    parse(object[i], d)
                } else {

                    let defaultType = 'string';

                    if (typeof object[i] === 'boolean') {
                        defaultType = 'boolean';
                    }

                    if (typeof object[i] === 'number') {
                        defaultType = 'integer';
                    }

                    schema[i] = {
                        type: defaultType
                    }
                }
            }


        }

        const schema = {};

        if (contentType === 'application/json') {
            parse(object, schema);
        }

        console.log('SCHEMA',schema)

        return {schema: {type: 'object' , properties: schema }};
    }
}