import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";

export default class ActionResolver
{
    resolve(channel : ChannelComponent): AppAction
    {
        for (const i in channel.getApp().getActions()) {
            if (channel.getApp().getActions()[i].getOperationId() === channel.getOperationId()) {
                return channel.getApp().getActions()[i];
            }
        }

        throw('Not found');
    }
}