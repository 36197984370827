import Component from "@/components/Flowbuilder/Component/Component";

export default class CollectionLookupComponent extends Component
{
    private collection: string;
    private field: string;
    private match: string;
    private response: any;

    constructor(
        collection: string,
        field: string,
        match: string,
        response: any,
    ) {
        super('');
        this.collection = collection;
        this.field = field;
        this.match = match;
        this.response = response;
    }

    getCollection(): string
    {
        return this.collection;
    }

    getField(): string
    {
        return this.field;
    }

    getMatch(): string
    {
        return this.match;
    }

    setCollection(collection: string): void
    {
        this.collection = collection;
    }

    setField(field: string): void
    {
        this.field = field;
    }

    setMatch(match: string): void
    {
        this.match = match;
    }

    setResponse(response: any): void
    {
        this.response = response;
    }

    getResponse(): any
    {
        return this.response;
    }
}