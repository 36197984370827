import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import MappingBuilder from "@/components/Flowbuilder/MappingTree/MappingBuilder";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";

export default class PathResponseTransformer
{
    transform(currentCollection: any, mappingValueObject: ValueObjectInterface, path: string): any
    {
        // lookup the data object with the given path in the currentCollection
        const parts = mappingValueObject.getPath().split('.');
        let ref : any =  currentCollection;

        // iterate by reference deeper into the currentCollection
        // so we finally end up with a component
        for (let i = 0; i < parts.length; i++) {
            ref = parts.length > (i+1) ? ref[parts[i]].getChildNodes() : ref[parts[i]];
        }

        // convert to channel component
        const channel : ChannelComponent = ref.getComponent() as ChannelComponent;
        let response : any = {};

        // find the response body
        channel.getApp().getActions().forEach((action: AppAction) => {
            if (action.getOperationId() === channel.getOperationId()) {
                const builder = new MappingBuilder();
                response = builder.getResponseBody(action.getResponses(), '');
            }
        })

        // prepare the selected path value (passed by the checkbox click event)
        const dataPath = path.replace(mappingValueObject.getPath() + '.', '');
        const dataParts = dataPath.replace(/\{|\}/g, '').split('.');

        // iterate by reference into the response
        // so we end up with an object
        let dataRef = response;

        for (let e = 0; e < dataParts.length; e++) {
            dataRef = dataRef[dataParts[e]];
        }

        return dataRef;
    }
}