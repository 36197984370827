import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";

export default class MappingValue implements ValueObjectInterface
{
    private label: string;
    private data: any;
    private type: string;
    private childNodes: ValueObjectInterface[];
    private path: string;

    constructor(label: string, data: any, type: string, path: string, childNodes: ValueObjectInterface[]) {
        this.label = label;
        this.data = data;
        this.type = type;
        this.childNodes = childNodes;
        this.path = path;
    }

    getLabel(): string
    {
        return this.label;
    }

    getData(): any
    {
        return this.data;
    }

    getType(): string
    {
        return this.type;
    }

    getPath(): string
    {
        return this.path;
    }

    getChildNodes(): ValueObjectInterface[]
    {
        return this.childNodes;
    }
}