import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import ActionResolver from "@/components/Flowbuilder/Helper/ActionResolver";
import ResponseSchemaResolver from "@/components/Flowbuilder/Helper/ResponseSchemaResolver";
import FirstOrNullComponent from "@/components/Flowbuilder/Component/FirstOrNull/FirstOrNullComponent";
import FirstOrNullValue from "@/components/Flowbuilder/MappingTree/ValueObject/FirstOrNullValue";
import MappingValue from "@/components/Flowbuilder/MappingTree/ValueObject/MappingValue";
import TriggerComponent from "@/components/Flowbuilder/Component/Trigger/TriggerComponent";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";
import CollectionLookupComponent from "@/components/Flowbuilder/Component/CollectionLookup/CollectionLookupComponent";

export default class MappingCollectionTransformer
{
    tree : any = {};

    constructor(collection: any[]) {
        this.iterator(collection, this.tree);
    }

    getTree(path: string): any
    {
        const parts = path.split('.');
        const tree : any[] = [];
        const last = parts[parts.length -1];

        const iterate = (mapValueObjects: any, depth = 0): any => {

            for (const i in mapValueObjects) {

                if (last === i && depth === parts.length -1) {
                    break;
                }

                if (mapValueObjects[i].getType() === ComponentType.LOOP) {

                    const x = depth + 1;
                    iterate(mapValueObjects[i].getChildNodes(), x);
                    break;
                } else if (mapValueObjects[i].getType() === ComponentType.DECISION) {

                    const x = depth + 2;
                    iterate(mapValueObjects[i].getChildNodes()[parts[depth + 1]].getChildNodes(), x);
                    break;
                } else {
                    tree.push(mapValueObjects[i]);
                }
            }
        };

        iterate(this.tree)
        return tree;
    }



    transform(collection: any[]): ValueObjectInterface[]
    {
        this.iterator(collection, this.tree);
        return this.tree;
    }

    iterator(collection: any[], reference: any[], path = ''): void
    {
        const types = {
            [ComponentType.LOOP]: 1,
            [ComponentType.DECISION]: 1,
            [ComponentType.DECISION_TRUE]: 1,
            [ComponentType.DECISION_FALSE]: 1
        };

        const concat = path === '' ? '' : '.';

        for (const i  in collection) {

            const n : number = parseInt(i);

            if (collection[i].getType() === ComponentType.TRIGGER) {
                const trigger: TriggerComponent = collection[i].getComponent() as TriggerComponent;
                reference[n] = this.getMapping('trigger', {}, path + concat + i, ComponentType.TRIGGER);
            }

            if (collection[i].getType() === ComponentType.CHANNEL) {

                const channel = collection[i].getComponent() as ChannelComponent;
                const data = this.getResponse(channel)

                reference[n] = this.getMapping(
                    channel.getApp().getTitle() + '(' + this.getOperationLabel(channel) + ')',
                    data.schema,
                    path + concat + i,
                    collection[i].getType()
                );
            }

            if (collection[i].getType() === ComponentType.COLLECTION_LOOKUP) {
                const lookup: CollectionLookupComponent = collection[i].getComponent() as CollectionLookupComponent;

                reference[n] = this.getMapping(
                    'My lookup ',
                    lookup.getResponse(),
                    path + concat + i,
                    ComponentType.COLLECTION_LOOKUP
                );
            }

            if (collection[i].getType() === ComponentType.FIRST_OR_NULL) {

                const firstOrNull: FirstOrNullComponent = collection[i].getComponent() as FirstOrNullComponent;

                reference[n] = this.getMapping(
                    firstOrNull.getName(),
                    firstOrNull.getValueObject(),
                    path + concat + i,
                    ComponentType.FIRST_OR_NULL
                );
            }

            if (types.hasOwnProperty(collection[i].getType())) {
                reference[i] = this.getMapping(
                    '',
                    {},
                    path + concat + i,
                    collection[i].getType()
                );
                this.iterator(collection[i].getChildNodes(), reference[i].getChildNodes(), path + concat + i)
            }
        }
    }

    getMapping(label: string, data: any = {}, path: string, componentType = ''): ValueObjectInterface
    {
        switch(componentType) {
        case ComponentType.FIRST_OR_NULL:
            return new FirstOrNullValue(label, data, componentType, path, []);
        default:
            return new MappingValue(label, data, componentType, path, []);
        }
    }

    getOperationLabel(channel : ChannelComponent): string
    {
        const resolver = new ActionResolver();
        const action = resolver.resolve(channel);
        return action.getDescription()
    }

    getResponse(channel : ChannelComponent): any
    {
        const resolver = new ActionResolver();
        const action = resolver.resolve(channel);

        const responseResolver : ResponseSchemaResolver = new ResponseSchemaResolver();
        let response = responseResolver.json(action)

        if (channel.getCustomResponse() !== null) {
            console.log('Got custom response for ' + channel.getOperationId())
            response = channel.getCustomResponse();
        }

        return responseResolver.toObject(response);
    }
}