

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        rules: Array
    },
    emits: ['removeRule'],
    methods: {
        removeRule(index: number): void
        {
            this.$emit('removeRule', index)
        }
    }
})

export default class TableRules extends Vue{}
