

import createStore from "@/store";
import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";


@Options({

    data() {
        return {
            accountId: '',
            user: null,
            tab: {
                active: 'account'
            }
        }
    },
    methods: {
        save() {

            const user = createStore.getters.getSignedInUser;

            ApiRequest('POST', '/account/' + user.id, this.user).then(() => {

                let user = this.$store.getters.getSignedInUser;
                user.firstname = this.user.firstname;

                createStore.commit('setSignedInUser', user);

            });
        },
        tabActive(element: MouseEvent) {
            const targetElement: HTMLElement = element.target as HTMLElement;
            this.tab.active = targetElement.getAttribute('data-tab');
        }
    },
    beforeMount() {
        this.$root.header = 'Settings';
    },
    mounted() {

        const user = createStore.getters.getSignedInUser;

        ApiRequest('GET', '/account/' + user.id).then(async (response: any) => {
            const result = await response.json();
            this.apps = result;
            this.loaded = true;
            this.user = result;
        });
    }
})

export default class Account extends Vue {}

