

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import LoopComponent from "@/components/Flowbuilder/Component/Loop/LoopComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, DropDownSearch, Select},
    props: {
        collectedData: Array,
        parentComponent: {},
        component: null
    },
    emits: ['saveComponent'],
    data() {
        return {
            mappingOptions: [],
            field: '',
            selectedOption: null,
            listeners: []
        }
    },
    beforeMount() {
        if (typeof this.$props.component !== 'undefined') {
            const loop : LoopComponent = this.$props.component as LoopComponent;
            this.field = loop.getField();
        }
    },
    methods: {
        pathSelected(path: string) {
            this.field = path;
        },
        setField(field: string): void
        {
            this.field = field;
        },
        save() : void
        {
            const loop : LoopComponent = this.$props.component as LoopComponent;
            loop.setField(this.field);

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: loop,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                }
            });

            window.dispatchEvent(e);
        },
        create() : void
        {
            const component: LoopComponent = new LoopComponent(null, this.field, []);
            const view : ViewComponent = new ViewComponent(ComponentType.LOOP, null, component, 0, 0, [])
            this.$emit('saveComponent', view)
        }
    }
})
export default class CreateLoop extends Vue{}

