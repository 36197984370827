export enum ComponentType
{
    TRIGGER = 'trigger',
    CHANNEL = 'channel',
    LOOP = 'loop',
    LOOP_LABEL = 'loopLabel',
    FUNNEL = 'funnel',
    DECISION = 'decision',
    DECISION_FALSE = 'decisionFalse',
    DECISION_TRUE = 'decisionTrue',
    CONDENSED_SET = 'condensedSet',
    FIRST_OR_NULL = 'firstOrNull',
    COLLECTION_LOOKUP = 'collectionLookup',
}