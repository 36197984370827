

import {Options, Vue} from "vue-class-component";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import FirstOrNullComponent from "@/components/Flowbuilder/Component/FirstOrNull/FirstOrNullComponent";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";
import PathResponseTransformer from "@/components/Flowbuilder/MappingTree/PathResponseTransformer";

@Options({
    components: {MappingTree},
    props: {
        collectedData: Array,
        parentComponent: {},
        component: null,
        currentCollection: Array,
        dataTree: Object,
    },
    data() {
        return {
            channels: [],
            component: null,
            collection: '',
            valueObject: {},
            responseObject: {},
            name: '',
            value: ''
        }
    },
    beforeMount() {
        if (typeof this.$props.component === 'undefined') {
            return;
        }
        const component: FirstOrNullComponent = this.$props.component as FirstOrNullComponent;
        this.name = component.getName();
        this.value = '{{' + Object.keys(component.getValueObject())[0] + '}}';
    },
    methods: {

        back() {
            this.$emit('back');
        },
        save() : void
        {
            const firstOrNull : FirstOrNullComponent = this.$props.component as FirstOrNullComponent;
            const path = this.collection.replace(/{{|}}|\[\]/g, '')

            firstOrNull.setValueObject(
                {[path + '.0']: this.responseObject}
            );

            firstOrNull.setName(this.name);

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: firstOrNull,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                }
            });

            window.dispatchEvent(e);
        },
        create() : void
        {
            const component: FirstOrNullComponent = new FirstOrNullComponent(null, this.valueObject, this.name);
            const view : ViewComponent = new ViewComponent(ComponentType.FIRST_OR_NULL, null, component, 0, 0, [])
            this.$emit('saveComponent', view)
        },
        pathSelected(path: string, mappingValueObject: ValueObjectInterface): any
        {
            const transformer : PathResponseTransformer = new PathResponseTransformer();
            this.responseObject = transformer.transform(this.$props.currentCollection, mappingValueObject, path);
        }
    }
})
export default class CreateFirstOrNull extends Vue{}

