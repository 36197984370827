

import {Options, Vue} from "vue-class-component";
import Node from "@/components/Flowbuilder/Views/MappingTree/Node.vue";
import TreeNode from "@/components/Flowbuilder/MappingTree/Views/TreeNode.vue";
import NodeCheckbox from "@/components/Flowbuilder/MappingTree/Views/NodeCheckbox.vue";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";


@Options({
    components: {TreeNode, Node},
    props: {
        label: String,
        collectedData: Array,
        id: String,
        visible: Boolean,
        displayButton: Boolean,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean,
        targetValue: String
    },
    emits: ['pathSelected', 'update:targetValue'],
    data() {
        return {
            listeners: [],
            tree: Object,
            visible: Boolean,
            value: '',
            checkboxes: []
        }
    },
    beforeMount() {
        this.visible = this.$props.visible;
        this.checkboxes = [];
    },
    methods: {
        registerCheckbox(checkbox: NodeCheckbox) {
            this.checkboxes.push(checkbox);
        },
        search(event: MouseEvent) {
            const val : string = (event.target as HTMLInputElement).value;
            this.tree.search(val)
            this.$emit('keyUp', val, this.$props.id)
        },
        toggleVisibility() {
            this.visible = !this.visible;
        },
        pathPropertySelected(path: string, mappingValue: ValueObjectInterface): void
        {
            this.checkboxes.forEach((checkbox: any) => {
                if (typeof checkbox.deselect() === 'function') {
                    checkbox.deselect()
                }
            })

            this.$emit('update:targetValue', path)
            this.$emit('pathSelected', path, mappingValue)
        }
    }
})


export default class MappingTree extends Vue{}
