

import {Options, Vue} from "vue-class-component";
import NodeCheckbox from "@/components/Flowbuilder/MappingTree/Views/NodeCheckbox.vue";

@Options({
    components: {NodeCheckbox, NodeIterator},
    emits: ['nodeSelected', 'checkboxCreated'],
    props: {
        data: Object,
        path: String,
        type: String,
        component: Object,
        mappingValueObject: Object,
        selectedValue: String,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean
    },
    data() {
        return {
            collapsed: true,
        }
    },
    methods: {
        deselectAll(): void {
            console.log(this.checkboxes)
        },
        nodeSelected(path: string): void
        {
            this.$emit('nodeSelected', path, this.$props.mappingValueObject)
        },
        checkboxCreated(checkbox: NodeCheckbox): void {
            this.$emit('checkboxCreated', checkbox)
        },
        toggle(): void
        {
            this.collapsed = !this.collapsed
        }
    }
})

export default class NodeIterator extends Vue{}


