

import {Options, Vue} from "vue-class-component";
import App from "@/components/Flowbuilder/Apps/App";
import ApiRequest from "@/api/ApiRequest";

@Options({
    props: {
        app: App,
        appAccountName: String,
        variables: Array,
    },
    data() {
        return {
            account: null,
            variableValues: []
        }
    },
    beforeMount() {
        this.variableValues = this.$props.variables
    },
    methods: {
        createAccount() {

            let data = {
                appName: this.$props.app?.getSlug(),
                name: this.$props.appAccountName,
                configuration: [],
            };

            if (this.app.appName === '' || this.appAccountName === '') {
                console.log('appName or appAccountName can not be empty')
                return;
            }

            ApiRequest('POST', '/app/account', data).then(async (response) => {

                const result = await response.json();

                if (typeof result.id === 'undefined') {
                    console.log('could not create app account');
                    return;
                }

                this.account = result;

                if (this.$props.app.getAuth().getOauth2Client() === 'google') {
                    this.connectToGoogle(this.selectedApp);
                }
            });
        },
        connectToGoogle() {
            ApiRequest(
                'GET', '/google/auth/url?appAccountId=' + this.account.id
            ).then((response) => {

                const opener: Window = window.open(
                    response.url,
                    'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                ) as Window;

                this.watcher = setInterval(() => {
                    if (opener.closed) {
                        try {
                            console.log('JOE',opener.document.documentElement.outerHTML)
                        } catch {}
                        clearInterval(this.watcher);
                        //this.$router.push({name: 'AppOverview'})
                        this.$emit('appAccountCreated')
                    }
                }, 500);
            });
        },
    }
})

export default class AuthOauth2 extends Vue{}

