import UserProvider from "@/library/Provider/UserProvider";

export default async function ApiRequest(method: string, path: string, data: any = null, additionalHeaders: any[] = []): Promise<any> {

    const provider: UserProvider = new UserProvider();
    const user = provider.getUser();
    const channelMonsterApiHost = process.env.VUE_APP_CM_API_HOST;

    const headers: any = {
        "Content-Type": "application/json"
    };

    if (user !== null && !provider.isExpired(user)) {
        headers.Authorization = 'Bearer ' + user.getToken().getToken();
    }

    additionalHeaders.forEach((key: any) => {
        const index = Object.keys(key)[0];
        headers[String(index)] = key[index];
    })

    const requestOptions: any = {
        method: method,
        headers: headers
    };

    if (data !== null) {
        requestOptions.body = JSON.stringify(data);
    }

    return await fetch(
        channelMonsterApiHost + path,
        requestOptions
    );
}
