

import {Options, Vue} from "vue-class-component";
import NodeIterator from "@/components/Flowbuilder/MappingTree/Views/NodeIterator.vue";
import NodeCheckbox from "@/components/Flowbuilder/MappingTree/Views/NodeCheckbox.vue";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";

@Options({
    components: {TreeNode, NodeCheckbox, NodeIterator},
    emits: ['pathPropertySelected', 'checkboxCreated'],
    props: {
        mappingValueObject: Object,
        type: String,
        component: Object,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean
    },
    data() {
        return {
            collapsed: true
        }
    },
    methods: {
        checkboxCreated(checkbox: NodeCheckbox): void
        {
            this.$emit('checkboxCreated', checkbox)
        },
        nodeSelected(path: string, mappingValue: ValueObjectInterface): void
        {
            this.$emit('pathPropertySelected', '{{' + path + '}}', mappingValue)
        },
        toggle(): void
        {
            this.collapsed = !this.collapsed;
        }
    }
})

export default class TreeNode extends Vue{}


