

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, SchemaIterator},
    emits: ['schemaUpdate'],
    props: {
        requestSchema: Object,
        requestBody: Object,
        values: Object,
        collectedData: Object,
        path: String
    },
    data() {
        return {
            schemaValueObject: {},
            parameters: {},
            arrayItems: []
        }
    },
    beforeMount() {
        this.schemaValueObject = this.$props.requestBody;
        this.generateValues(this.$props.requestSchema, this.$props.requestBody)
    },
    methods: {
        getValue(index: string, defaultValue: any): string
        {
            const realPath = (this.$props.path + '.' + index).replace('schema.', '');
            const parts = realPath.split('.');

            if (typeof this.$props.values === 'undefined') {
                return defaultValue;
            }

            let temp: any = JSON.parse(
                JSON.stringify(this.$props.values)
            );

            for (const i in parts) {
                if (temp.hasOwnProperty(parts[i])) {
                    temp = temp[parts[i]];
                } else {
                    temp = defaultValue;
                }
            }

            return temp;
        },
        getPath(index: string): string
        {
            return this.$props.path === '' ? index : this.$props.path + '.' + index;
        },
        generateValues(schema:any, object: any) {

            for (const i in schema) {
                if (schema[i].type === 'object') {
                    object[i] = {};
                }

                if (schema[i].type === 'array') {

                    try {
                        object[i] = this.getValue(i, []);
                    } catch (e) {}

                    if (object[i].length === 0) {
                        this.arrayItems.push({[0]: schema[i].items})
                    } else {
                        for (let a = 0; a < object[i].length; a++) {
                            this.arrayItems.push({[a]: schema[i].items})
                        }
                    }
                }

                if (schema[i].type !== 'object' && schema[i].type !== 'array') {
                    object[i] = this.getValue(i, '');
                }
            }

        },
        addArrayItem(schema: any, ref: any) : void {
            this.arrayItems.push({[ref.length]:schema})
        },
        delArrayItem(reference: any, arrayIndex: number, schemaIndex: string): void
        {
            const items = [];

            for (let i = 0; i < this.arrayItems.length; i++) {
                if (i !== arrayIndex) {
                    items.push(this.arrayItems[i]);
                }
            }

            const refItems = [];

            for (let rr = 0; rr < this.schemaValueObject[schemaIndex].length; rr++) {
                if (rr !== arrayIndex) {
                    refItems.push(
                        this.schemaValueObject[schemaIndex][rr]
                    )
                }
            }

            this.arrayItems = items;
            this.schemaValueObject[schemaIndex] = refItems;

        }
    }
})


export default class SchemaIterator extends Vue{}

