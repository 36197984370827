


import {Options, Vue} from "vue-class-component";
import CollectionLookupComponent from "@/components/Flowbuilder/Component/CollectionLookup/CollectionLookupComponent";

@Options({
    props: {
        component: CollectionLookupComponent
    }
})

export default class CollectionLookupIcon extends Vue{}
