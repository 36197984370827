

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import {useRoute} from "vue-router";
import {toast} from "vue3-toastify";

@Options({
    data() {
        return {
            workflow: {
                id: '',
                name: '',
                published: false
            }
        }
    },
    methods: {
        togglePublished() {
            this.workflow.published = !this.workflow.published
        },
        update() {
            ApiRequest('POST', '/workflow/' + this.workflow.id, this.workflow).then(() => {
                toast.success('Workflow saved!');
            })
        }
    },
    mounted() {
        const route = useRoute()
        ApiRequest('GET', '/workflow/' + route.params.id).then(async (response: any) => {
            this.workflow = await response.json();
        })
    }
})

export default class WorkflowEdit extends Vue{}
