import Channel from "@/components/Flowbuilder/Component/Channel/View/Icon/Channel.vue";
import Loop from "@/components/Flowbuilder/Component/Loop/View/Icon/Loop.vue";
import Decision from "@/components/Flowbuilder/Component/Decision/View/Icon/Decision.vue";
import DecisionLane from "@/components/Flowbuilder/Component/Decision/View/Icon/DecisionLane.vue";
import {Vue} from "vue-class-component";
import Trigger from "@/components/Flowbuilder/Component/Trigger/View/Icon/Trigger.vue";
import TriggerEdit from "@/components/Flowbuilder/Component/Trigger/View/Edit/TriggerEdit.vue";
import CreateDecision from "@/components/Flowbuilder/Component/Decision/View/Edit/CreateDecision.vue";
import CreateChannel from "@/components/Flowbuilder/Component/Channel/View/Edit/CreateChannel.vue";
import CreateLoop from "@/components/Flowbuilder/Component/Loop/View/Edit/CreateLoop.vue";
import Funnel from "@/components/Flowbuilder/Component/Funnel/View/Icon/Funnel.vue";
import CreateFunnel from "@/components/Flowbuilder/Component/Funnel/View/Edit/CreateFunnel.vue";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import CreateCondensedSet from "@/components/Flowbuilder/Component/CondensedSet/View/Edit/CreateCondensedSet.vue";
import CondensedSet from "@/components/Flowbuilder/Component/CondensedSet/View/Icon/CondensedSet.vue";
import LoopLabel from "@/components/Flowbuilder/Component/Loop/View/Icon/LoopLabel.vue";
import FirstOrNullIcon from "@/components/Flowbuilder/Component/FirstOrNull/View/Icon/FirstOrNullIcon.vue";
import CreateFirstOrNull from "@/components/Flowbuilder/Component/FirstOrNull/View/Edit/CreateFirstOrNull.vue";
import CollectionLookupIcon
    from "@/components/Flowbuilder/Component/CollectionLookup/View/Icon/CollectionLookupIcon.vue";
import CreateCollectionLookup
    from "@/components/Flowbuilder/Component/CollectionLookup/View/Edit/CreateCollectionLookup.vue";

export default class ComponentViewFactory
{
    create(type: string): Vue
    {
        let component: any;

        switch (type) {
        case ComponentType.TRIGGER:
            component = Trigger;
            break;
        case ComponentType.CHANNEL:
            component = Channel;
            break;
        case ComponentType.FUNNEL:
            component = Funnel;
            break;
        case ComponentType.LOOP_LABEL:
            component = LoopLabel;
            break;
        case ComponentType.LOOP:
            component = Loop;
            break;
        case ComponentType.DECISION:
            component = Decision;
            break;
        case ComponentType.CONDENSED_SET:
            component = CondensedSet;
            break;
        case ComponentType.FIRST_OR_NULL:
            component = FirstOrNullIcon;
            break;
        case ComponentType.COLLECTION_LOOKUP:
            component = CollectionLookupIcon;
            break;
        case ComponentType.DECISION_TRUE:
        case ComponentType.DECISION_FALSE:
            component = DecisionLane;
            break;
        default:
            throw new Error('unknown component ' + type)
        }

        return component;
    }

    edit(type: string): Vue
    {
        let component: any;

        switch (type) {
        case ComponentType.TRIGGER:
            component = TriggerEdit;
            break;
        case ComponentType.FUNNEL:
            component = CreateFunnel;
            break;
        case ComponentType.LOOP:
            component = CreateLoop;
            break;
        case ComponentType.COLLECTION_LOOKUP:
            component = CreateCollectionLookup;
            break;
        case ComponentType.DECISION:
            component = CreateDecision;
            break;
        case ComponentType.CHANNEL:
            component = CreateChannel
            break;
        case ComponentType.CONDENSED_SET:
            component = CreateCondensedSet;
            break;
        case ComponentType.FIRST_OR_NULL:
            component = CreateFirstOrNull;
            break;
        default:
            throw new Error('unknown component' + type)
        }

        return component;
    }
}