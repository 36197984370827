

import {Options, Vue} from "vue-class-component";
import CreateChannel from "@/components/Flowbuilder/Component/Channel/View/Edit/CreateChannel.vue";
import CreateDecision from "@/components/Flowbuilder/Component/Decision/View/Edit/CreateDecision.vue";
import CreateLoop from "@/components/Flowbuilder/Component/Loop/View/Edit/CreateLoop.vue";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import CreateFunnel from "@/components/Flowbuilder/Component/Funnel/View/Edit/CreateFunnel.vue";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import CreateCondensedSet from "@/components/Flowbuilder/Component/CondensedSet/View/Edit/CreateCondensedSet.vue";
import CreateFirstOrNull from "@/components/Flowbuilder/Component/FirstOrNull/View/Edit/CreateFirstOrNull.vue";
import CreateCollectionLookup
    from "@/components/Flowbuilder/Component/CollectionLookup/View/Edit/CreateCollectionLookup.vue";

@Options({
    computed: {
        ComponentType() {
            return ComponentType
        }
    },
    components: {
        CreateCollectionLookup,
        CreateFirstOrNull, CreateCondensedSet, CreateFunnel, CreateLoop, CreateDecision, CreateChannel},
    props: {
        apps: Array,
        collectedData: [],
        component: null,
        parentComponent: null,
        currentCollection: null,
        position: null,
        path: {},
    },
    data() {
        return {
            componentType: '',
        }
    },
    beforeMount() {
        this.componentType = '';
    },
    emits: ['saveComponent'],
    methods: {
        back() {
            this.componentType = '';
        },
        saveComponent(component: ViewComponent) {

            const e = new CustomEvent('addComponent', {
                detail: {
                    component: component,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                    position: this.$props.position,
                    path: this.$props.path
                }
            });

            window.dispatchEvent(e);
        },
        setComponentType(type: string) {
            this.componentType = type;
        }
    }
})

export default class Create extends Vue{}

