import Component from "@/components/Flowbuilder/Component/Component";

export default class CondensedSetComponent extends Component
{
    id: string | null;
    condensedSet : any = {};


    constructor(
        id: string | null,
        condensedSet: any
    ) {
        super('');
        this.id = id;
        this.condensedSet = condensedSet;
    }

    getId(): string | null
    {
        return this.id;
    }

    getCondensedSet(): any
    {
        return this.condensedSet;
    }

    setCondensedSet(condensedSet: any): void
    {
        this.condensedSet = condensedSet;
    }
}