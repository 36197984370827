

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import App from "@/components/Flowbuilder/Apps/App";
import ChannelParameters from "@/components/Flowbuilder/Component/Channel/View/Edit/Parameters/ChannelParameters.vue";
import RequestBody from "@/components/Flowbuilder/Component/Channel/View/Edit/RequestBody/RequestBody.vue";
import ApiRequest from "@/api/ApiRequest";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import ActionResolver from "@/components/Flowbuilder/Helper/ActionResolver";
import ResponseYamlTransformer from "@/components/Flowbuilder/MappingTree/ResponseYamlTransformer";

@Options({
    emits: ['storeYamlResult'],
    components: {
        RequestBody,
        ChannelParameters,
        MappingTree
    },
    props: {
        component: null,
        collectedData: Array,
        currentCollection: Array
    },
    data() {
        return {
            field: '',
            channels: [],
            component: null,
            parameters: [],
            account: null,
            requestBody: {},
            requestSchema: {},
            action: null,
            condensedSet: {},
            success: null,
            reason: '',
            yamlResult: ''
        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined') {
            this.selectComponent(
                this.$props.component
            )
        }
    },
    methods: {

        useResult(): void
        {
            this.$emit('storeYamlResult', this.yamlResult)
        },


        execute(): void {

            const step = {
                componentType: ComponentType.CHANNEL,
                appAccount: this.component.getAppAccount(),
                mapping: this.$refs.requestBody.getRequestBody(),
                parameters: this.parameters,
                action: this.component.getOperationId(),
                componentReferenceId: this.component.getComponentReferenceId()
            }

            ApiRequest('POST', '/channel/execute', step).then(async (response: any) => {
                const result = await response.json();
                this.success = null;

                if (response.status === 201 || response.status === 200) {
                    this.yamlResult = (new ResponseYamlTransformer()).export(result, response.headers.get('content-type'));
                    this.success = true;
                } else {
                    //console.log('An error occurred while fetching a response')
                    this.success = false;
                    this.reason = result.body;
                }
            });
        },
        selectComponent(component: ChannelComponent): void
        {
            const resolver = new ActionResolver();
            this.component = component;

            const currentAction = resolver.resolve(component);
            const actionRequestBody = currentAction?.getRequestBody();

            this.requestSchema = {};
            this.currentAction = currentAction;
            this.parameters = this.currentAction?.getParameters();

            if (actionRequestBody === null) {
                console.log('no request body schema present ')
                return;
            }

            const requestContentTypeKey = Object.keys(actionRequestBody.content)[0];

            if (actionRequestBody.content[requestContentTypeKey].length === 0) {
                this.move(2);
                return;
            }

            // pass everything in the content section, incl "schema"
            this.requestSchema = actionRequestBody.content[requestContentTypeKey];
        },
        label(app: App, operationId: string): string
        {
            let currentAction : AppAction | null = null;
            let label = '';

            for (let i in app.getActions()) {
                if (app.getActions()[i].getOperationId() === operationId) {
                    currentAction = app.getActions()[i];
                    break;
                }
            }

            label = operationId;

            if (currentAction?.getSummary() !== '') {
                label = currentAction?.getSummary() ?? '';
            }

            if (currentAction?.getDescription() !== '' ) {
                label = currentAction?.getDescription() ?? '';
            }

            return label;
        }
    }
})


export default class TestChannel extends Vue{}
