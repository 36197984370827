import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import TriggerComponent from "@/components/Flowbuilder/Component/Trigger/TriggerComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import CondensedSetComponent from "@/components/Flowbuilder/Component/CondensedSet/CondensedSetComponent";
import FirstOrNullComponent from "@/components/Flowbuilder/Component/FirstOrNull/FirstOrNullComponent";
import ObjectMapper from "@/components/Flowbuilder/MappingTree/ObjectMapper";

export default class MappingBuilder
{
    mapping(collection: ViewComponent[]): any {

        const map = [];

        const trigger: TriggerComponent = collection[0].getComponent() as TriggerComponent;

        const t = trigger.getType() === 'webhook'
            ? trigger.getParameters().request
            : [];

        map.push({source: 'webhook', mapping: t});

        this.collectionIterator(collection, map);

        return map;
    }

    /**
     * Collect only if the view type is "channel" or "funnel"
     *  these 2 types need to create a view of the response body
     *
     * Iterate on childNodes only if the type is a "loop"
     *  this represents a sub process
     */
    collectionIterator(collection: ViewComponent[], map: any = []): any {

        const allowedTypes: any = {
            [ComponentType.DECISION]: true,
            [ComponentType.DECISION_TRUE]: true,
            [ComponentType.DECISION_FALSE]: true,
        }

        for (const i in collection) {

            if (collection[i].getType() === ComponentType.LOOP && collection[i].childNodes.length > 0) {
                this.collectionIterator(collection[i].childNodes, map)
            } else if (allowedTypes.hasOwnProperty(collection[i].getType())) {
                this.collectionIterator(collection[i].childNodes, map)
            } else if (collection[i].getType() === ComponentType.CHANNEL) {

                const channel: ChannelComponent = collection[i].getComponent() as ChannelComponent;
                const action: AppAction | null = this.getAction(channel);

                if (action !== null) {
                    map[map.length] = {
                        source: channel.getOperationId(),
                        mapping: this.getResponseBody(
                            action.getResponses(),
                            channel.getOperationId()
                        ),
                        type: collection[i].getType(),
                        component: channel
                    };
                }
            } else if (collection[i].getType() === ComponentType.CONDENSED_SET) {
                const condensedSet: CondensedSetComponent = collection[i].getComponent() as CondensedSetComponent;
                map[map.length] = {source: 'CondensedSet', mapping: condensedSet.getCondensedSet(), type: collection[i].getType(), component: condensedSet};
            } else if (collection[i].getType() === ComponentType.FIRST_OR_NULL) {
                const firstOrNull: FirstOrNullComponent = collection[i].getComponent() as FirstOrNullComponent;
                map[map.length] = {source: 'FirstOrNull', mapping: firstOrNull.getValueObject(), type: collection[i].getType(), component: firstOrNull};
            }
        }

        return map;
    }

    getResponseBody(responses: any, label: string): any
    {
        const responseStatusCodeKey: string[] = Object.keys(responses);
        const responseContentTypeKey: string[] = Object.keys(responses[responseStatusCodeKey[0]].content);
        const o : ObjectMapper = new ObjectMapper();

        return o.map(
            responses[responseStatusCodeKey[0]].content[responseContentTypeKey[0]].schema,
            label
        );
    }

    getAction(channel : ChannelComponent): AppAction | null
    {
        let selectedAction = null;

        channel.getApp().getActions().forEach( (action: AppAction) => {
            if (action.getOperationId() === channel.getOperationId()) {
                selectedAction = action;
            }
        })

        return selectedAction;
    }
}