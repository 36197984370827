

import {Options, Vue} from "vue-class-component";
import Flow from "@/components/Flowbuilder/Flow.vue";
import ApiRequest from "@/api/ApiRequest";
import AppCollection from "@/components/Flowbuilder/Apps/AppCollection";

@Options({
    computed: {
        loaded() {
            return this.workflow !== null && this.apps !== null
        }
    },
    data() {
        return {
            workflow: null,
            apps: null,
        }
    },
    components: {Flow},
    mounted() {
        let parts = document.location.href.split('/');

        ApiRequest('GET', '/workflow/' + parts[parts.length -2]).then (async (response) => {
            this.workflow = await response.json();
        });

        ApiRequest('GET', '/app').then (async (response) => {
            const json = await response.json();
            this.apps = new AppCollection(json)
        });
    }
})
export default class WorkflowEditor extends Vue {}
