import AppAction from "@/components/Flowbuilder/Apps/AppAction";

export default class ResponseSchemaResolver
{
    json(action: AppAction): any
    {
        return this.find('application/json', action);
    }

    find(contentType: string, action: AppAction): any
    {
        for (const i in action.getResponses()) {
            if (String(i).substring(0, 1) === '2') {
                const response : any = action.getResponses()[i];
                return response.content[contentType];
            }
        }

        return null;
    }

    toObject(schema: any): any
    {
        const t : any = {};
        this.recursiveSchemaIterator(schema, t)
        return t;
    }

    recursiveSchemaIterator(schema: any, reference: any, isArray = false): void
    {
        for (const i in schema) {

            if (schema[i].type === 'object') {

                if (!isArray) {
                    reference[i] = {};
                }
                this.recursiveSchemaIterator(
                    schema[i].properties,
                    !isArray ? reference[i] : reference
                );
            }

            if (schema[i].type === 'array') {
                const t: any = {};
                reference[i] = t;

                this.recursiveSchemaIterator(
                    {0:schema[i].items},
                    t,
                    true
                )
            }

            if (schema[i].type === 'string' || schema[i].type === 'integer') {
                reference[i] = '';
            }
        }
    }
}