
import TriggerComponent from "@/components/Flowbuilder/Component/Trigger/TriggerComponent";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import LoopComponent from "@/components/Flowbuilder/Component/Loop/LoopComponent";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import DecisionComponent from "@/components/Flowbuilder/Component/Decision/DecisionComponent";
import FunnelComponent from "@/components/Flowbuilder/Component/Funnel/FunnelComponent";
import CondensedSetComponent from "@/components/Flowbuilder/Component/CondensedSet/CondensedSetComponent";
import FirstOrNullComponent from "@/components/Flowbuilder/Component/FirstOrNull/FirstOrNullComponent";
import CollectionLookupComponent from "@/components/Flowbuilder/Component/CollectionLookup/CollectionLookupComponent";

export default class WorkflowExport {

    workflow: any;

    collection: ViewComponent[];

    constructor(workflow: object, collection: ViewComponent[]) {
        this.workflow = workflow;
        this.collection = collection;
    }

    export(){

        const trigger : TriggerComponent | null = this.collection[0].getComponent() as TriggerComponent;

        return {
            id: this.workflow.id,
            userId: this.workflow.userId,
            name: this.workflow.name,
            published: false,
            triggerType: trigger?.getType(),
            triggerParameters: trigger?.getParameters(),
            steps: this.createSteps(
                this.collection
            )
        };
    }

    createSteps(collection: any[]): any[] {

        const steps : any[] = [];

        collection.forEach((item: ViewComponent) => {

            if (item.getType() === ComponentType.LOOP) {

                const loop : LoopComponent = item.getComponent() as LoopComponent;

                steps.push({
                    componentType: item.getType(),
                    field: loop.getField(),
                    componentReferenceId: loop.getComponentReferenceId(),
                    childNodes: this.createSteps(
                        item.getChildNodes()
                    ),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                })
            }

            if (item.getType() === ComponentType.FIRST_OR_NULL) {

                const firstOrNull : FirstOrNullComponent = item.getComponent() as FirstOrNullComponent;

                const step: any = {
                    componentType: item.getType(),
                    valueObject: firstOrNull.getValueObject(),
                    name: firstOrNull.getName(),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.CONDENSED_SET) {

                const condensedSet : CondensedSetComponent = item.getComponent() as CondensedSetComponent;

                const step: any = {
                    componentType: item.getType(),
                    condensedSet: condensedSet.getCondensedSet(),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.COLLECTION_LOOKUP) {

                const lookup : CollectionLookupComponent = item.getComponent() as CollectionLookupComponent;

                const step: any = {
                    componentType: item.getType(),
                    collection: lookup.getCollection(),
                    field: lookup.getField(),
                    match: lookup.getMatch(),
                    response: lookup.getResponse(),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.FUNNEL) {

                const funnel : FunnelComponent = item.getComponent() as FunnelComponent;

                const step: any = {
                    componentType: ComponentType.FUNNEL,
                    field: funnel.getField(),
                    comparison: funnel.getComparison(),
                    value: funnel.getValue(),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.CHANNEL) {

                const channel : ChannelComponent = item.getComponent() as ChannelComponent;

                const mapping: any = {};

                for (const i in channel.getRequestBody()) {
                    mapping[i] = channel.getRequestBody()[i];
                }

                const step: any = {
                    componentType: ComponentType.CHANNEL,
                    appAccount: channel.getAppAccount(),
                    mapping: channel.getRequestBody(),
                    parameters: channel.getParameters(),
                    action: channel.getOperationId(),
                    componentReferenceId: channel.getComponentReferenceId(),
                    customResponse: channel.getCustomResponse(),
                    x: item.getHtmlContainer()?.offsetLeft,
                    y: item.getHtmlContainer()?.offsetTop,
                };

                if (channel.getId() !== null && channel.getId() !== '') {
                    step.id =  channel.getId();
                }

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION_TRUE) {

                const step: any = {
                    componentType: ComponentType.DECISION_TRUE,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION_FALSE) {

                const step: any = {
                    componentType: ComponentType.DECISION_FALSE,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION) {

                const decision : DecisionComponent = item.getComponent() as DecisionComponent;

                const step: any = {
                    componentType: ComponentType.DECISION,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                    rules: decision.getRules()
                };

                if (decision.getId() !== null && decision.getId() !== '') {
                    step.id = decision.getId();
                }

                steps.push(step)
            }
        })

        return steps;
    }
}