

import {Options, Vue} from "vue-class-component";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";

@Options({
    emits: ['nodeSelected', 'checkboxCreated'],
    props: {
        id: String,
        label: String,
        value: String,
        selectedValue: String,
        isCollection: Boolean,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean,
        mappingValueObject: Object
    },
    mounted() {
        this.$emit('checkboxCreated', this)
    },
    data() {
        return {
            checked: false
        }
    },
    methods: {
        deselect() {
            this.checked = false;
        },
        nodeSelected(): void
        {
            let value = this.$props.mappingValueObject.getPath() + this.$props.value;

            if (this.$props.mappingValueObject.getType() === ComponentType.FIRST_OR_NULL) {
                value = this.$props.value.substring(1);
            }

            this.$emit('nodeSelected', value)
        }
    }
})

export default class NodeCheckbox extends Vue {
}
