import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import ValueObjectInterface from "@/components/Flowbuilder/MappingTree/ValueObject/ValueObjectInterface";

export default class PathResolver
{
    getParentFromPath(path: string, dataTree: ValueObjectInterface[]): any
    {
        const parts: string[] = path.split('.');

        /**
         * walk through the given path of the dataTree
         *  by the given parameter "path"
         *
         *  The path can be 2.1.0.1.contact
         *   where "2.1.0.1" is the actual path
         *   and "contact" is part of the data that
         *   resides in the getData() return value
         */
        const objectData = (index: number, treeReference: any): any => {
            if (typeof treeReference[parts[index]] !== 'undefined' && treeReference[parts[index]].getChildNodes().length > 0) {
                return objectData(index + 1, treeReference[parts[index]].getChildNodes())
            } else {
                return collection(index + 1, treeReference[parts[index]].getData());
            }
        }

        const collection = (index: number, dataReference: any): any => {

            const key = parts[index];

            if (typeof dataReference[key] === 'object' && (index + 1) < parts.length) {
                return collection(index + 1, dataReference[key])
            } else {
                return dataReference[key]
            }
        }

        return objectData(0, dataTree);
    }

    /**
     * Generally used by add new channels to the existing flowbuilder
     */
    getParentChildNodeCollection(path: string, collectedData: any[]): ViewComponent[]
    {
        const parts = path.split('.');

        parts.splice(parts.length -1, 1);
        let ref:any = collectedData;

        parts.forEach((part: string) => {
            if (typeof  ref[part] !== 'undefined') {
                ref = ref[part]
            } else if (typeof ref.childNodes[part] !== 'undefined') {
                ref = ref.childNodes[part];
            } else {
                console.log('i give up')
            }
        });

        return ref.childNodes ?? ref;
    }
}
