

import {Options, Vue} from "vue-class-component";
import IncomingDataView from "@/components/Flowbuilder/Component/Trigger/View/Edit/webhook/IncomingDataView.vue";
import TriggerComponent from "@/components/Flowbuilder/Component/Trigger/TriggerComponent";
import Pusher from "pusher-js";

@Options({
    components: {
        IncomingDataView
    },
    data() {
        return {
            capturing: false,
            requestReceived: false,
            apiHost: process.env.VUE_APP_CM_API_HOST,
            trigger: {
                parameters: {
                    request: null
                },
                type: 'webhook'
            },
        }
    },
    props: {
        component: TriggerComponent,
        workflow: Object
    },
    emits: [
        "triggerUpdate"
    ],
    methods: {
        startCapture() {
            this.capturing = true;
        },
        copy() {

            let input = document.getElementById('webhookUrl') as HTMLInputElement;
            input.focus();
            input.select();

            if (!navigator.clipboard) {
                document.execCommand('copy');
                return;
            }

            const copyButton = document.getElementsByClassName('img-copy-button')[0] as HTMLElement;
            // const copySuccess = document.getElementsByClassName('copy-success')[0] as HTMLElement;

            navigator.clipboard.writeText(input.value).then(function() {
                copyButton.style.display = 'none';
                //copySuccess.style.display = 'block';

                setTimeout(() => {
                    copyButton.style.display = 'block';
                    //copySuccess.style.display = 'none';
                }, 800);
            });
        },
        save() {
            this.$emit('triggerUpdate', this.trigger);
        },
        setRequestData(data: Array<any>, timeout: number) {

            this.trigger.parameters.request = data;

            setTimeout(() => {
                this.$refs['data-view'].createTree(data, 'tree-bundle');

            }, timeout);
        },
        tree() {
            if (typeof this.$props.component.getParameters().request !== 'undefined') {
                this.$refs['data-view'].createTree(this.$props.component.getParameters().request);
            }
        }
    },
    mounted() {

        const pusher = new Pusher('59f5d05e5fc70e448c03', {
            cluster: 'eu',
        });

        const channel = pusher.subscribe(this.$props.workflow.id);
        //
        channel.bind('trigger', (data: any) => {

            if (!this.capturing) {
                return;
            }

            this.$refs['data-view'].createTree(
                JSON.parse(data)
            );

            this.capturing = false;
            this.requestReceived = true;
            this.setRequestData = JSON.parse(data);
            this.trigger.parameters.request = JSON.parse(data)

        });

        if (typeof this.$props.component.getParameters().request !== 'undefined') {
            this.$refs['data-view'].createTree(this.$props.component.getParameters().request);
        }

    }
})

export default class Webhook extends Vue{}
