

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import AppAccount from "@/models/AppAccount";
import AppAccountConfig from "@/models/AppAccountConfig";
import App from "@/components/Flowbuilder/Apps/App";
import AuthViewFactory from "@/components/Flowbuilder/Factory/AuthViewFactory";

@Options({
    emits: ['appAccountCreated', 'cancelCreateAppAccount'],
    props: {
        app: null,
        appAccount: null,
    },
    data() {
        return {
            authView: Vue,
            appAccountName: '',
            authType: String,
            authClient: String,
            app: App,
            account: Object,
            watcher: null,
            variables: []
        }
    },
    mounted() {
        const authViewFactory: AuthViewFactory = new AuthViewFactory();
        this.authView = authViewFactory.create(
            this.$props.app
        )
    },
    methods: {
        test() {

            let data = {
                appName: this.app.appName,
                name: this.appAccountName,
                configuration: [],
            };

            if (this.app.appName === '' || this.appAccountName === '') {
                console.log('appName or appAccountName can not be empty')
                return;
            }

            ApiRequest('POST', '/app/account', data).then(async (response) => {

                const result = await response.json();

                if (typeof result.id === 'undefined') {
                    console.log('could not create app account');
                    return;
                }

                this.account = result;
                this.callGoogle(this.selectedApp);
            });
        },

        cancelCreateAccount() {
            this.$emit('cancelCreateAppAccount')
        },
        createAccount() {

            const configuration: Array<any> = [];

            let data = {
                appName: this.$props.app.getSlug(),
                name: (document.getElementById('app-name') as HTMLInputElement).value,
                configuration: configuration
            };

            document.querySelectorAll('input[data-configuration]').forEach((element: Element) => {
                const input = element as HTMLInputElement;

                data.configuration.push({
                    key: input.name,
                    value: input.value,
                });
            })

            ApiRequest('POST', '/app/account', data).then(async (response) => {
                const result = await response.json();
                const configs: Array<AppAccountConfig> = [];

                result.configuration.forEach((config: any) => {
                    configs.push(
                        new AppAccountConfig(config.id, config.key, config.value, config.created)
                    )
                });

                const appAccount: AppAccount = new AppAccount(
                    response.id,
                    response.appName,
                    response.name,
                    response.userId,
                    response.created,
                    configs
                )
                this.$emit('appAccountCreated', appAccount)
            });
        }
    }
})

export default class CreateAccount extends Vue{}
