import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchemaIterator = _resolveComponent("SchemaIterator")!

  return (_openBlock(), _createBlock(_component_SchemaIterator, {
    requestSchema: this.$props.requestSchema,
    requestBody: this.schemaValueObject,
    collectedData: this.$props.collectedData,
    values: this.$props.values,
    path: ''
  }, null, 8, ["requestSchema", "requestBody", "collectedData", "values"]))
}