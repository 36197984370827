
import Component from "@/components/Flowbuilder/Component/Component";

export default class ViewComponent
{
    type: string;
    htmlContainer: HTMLElement | null;
    component: Component;
    positionX = 0;
    positionY = 0;
    childNodes: ViewComponent[] = [];
    path: string[] = [];
    id = '';

    constructor(
        type: string,
        htmlContainer: HTMLElement | null,
        component: Component,
        positionX = 0,
        positionY = 0,
        childNodes: ViewComponent[] = []
    ) {
        this.type = type;
        this.htmlContainer = htmlContainer;
        this.component = component;
        this.positionX = positionX;
        this.positionY = positionY;
        this.childNodes = childNodes;
    }

    getHtmlContainer(): HTMLElement
    {
        return this.htmlContainer ?? (document.createElement('div') as HTMLElement);
    }

    getType(): string
    {
        return this.type;
    }

    getComponent(): Component
    {
        return this.component;
    }

    setPositionX(positionX: number) : void
    {
        this.positionX = positionX;
    }

    setPositionY(positionY: number) : void
    {
        this.positionY = positionY;
    }

    getPositionX(): number
    {
        return this.positionX;
    }

    getPositionY(): number
    {
        return this.positionY;
    }

    setHtmlContainer(element: HTMLElement) : void
    {
        this.htmlContainer = element;
    }

    getChildNodes(): ViewComponent[]
    {
        return this.childNodes;
    }

    setChildNodes(collection: ViewComponent[]): void
    {
        this.childNodes = collection;
    }

    setPath(path: string[]) : void
    {
        this.path = path;
    }

    getPath(): string[] {
        return this.path;
    }

    setId(id : string): void
    {
        this.id = id;
    }

    getId(): string
    {
        return this.id;
    }
}
