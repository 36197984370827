

import { Options, Vue } from "vue-class-component";
import ChannelComponent from "@/components/Flowbuilder/Component/Channel/ChannelComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import ActionResolver from "@/components/Flowbuilder/Helper/ActionResolver";

@Options({
    components: { },
    props: {
        refs: Array,
        id: Number,
        app: Object,
        component: ChannelComponent
    },
    data() {
        return {
            displayTrashCan: false,
            labelAction: ''
        }
    },
    mounted() {

        const resolver : ActionResolver = new ActionResolver();
        const currentAction : AppAction | null = resolver.resolve(this.$props.component)

        this.labelAction = this.$props.component.getOperationId();

        if (currentAction?.getSummary() !== '') {
            this.labelAction = currentAction?.getSummary();
        }

        if (currentAction?.getDescription() !== '' ) {
            this.labelAction = currentAction?.getDescription();
        }
    },
    methods: {
        displayTrash() {
            this.displayTrashCan = true;
        },
        hideTrash() {
            this.displayTrashCan = false;
        }
    }
})
export default class Channel extends Vue {}
