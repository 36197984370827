import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "node-checkbox" }
const _hoisted_2 = ["value", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((this.$props.leafIsSelectable && !this.$props.isCollection) || (this.$props.collectionIsSelectable && this.$props.isCollection))
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.checked) = $event)),
          value: this.$props.value,
          id: this.$props.id,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (this.nodeSelected()))
        }, null, 8, _hoisted_2)), [
          [_vModelCheckbox, this.checked]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      for: this.$props.id,
      class: _normalizeClass((this.$props.isCollection ? 'tree-collection-node' : ''))
    }, _toDisplayString(this.$props.label), 11, _hoisted_3)
  ]))
}